import { Typography } from "antd";
import { DoacoesHerancaData, Question } from "./interfaces";
import {
  DoacoesHerancas,
  DoacoesHerancasProps,
} from "./Components/DoacoesHerancas";

export const validateDoacoesHerancaData = (data: DoacoesHerancaData) => {
  return true;
};

export const questions: Question[] = [
  {
    id: "doacoesHerancas",
    title: "Complete seus dados de doações e heranças",
    children: (
      <Typography style={{ marginTop: "32px", textAlign: "right" }}>
        Lembre-se de clicar em finalizar para salvar seus dados
      </Typography>
    ),
    formItems: [
      {
        name: "doacoesHerancas",
        FormItem: ({ rest }) => (
          <DoacoesHerancas {...(rest as DoacoesHerancasProps)} />
        ),
      },
    ],
  },
];
