import { useLocation, useNavigate } from "react-router-dom";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthContext";

type History = {
  pathname: string;
};

interface IHistoryContext {
  history: History[];
  handleBackHistory: () => void;
}

const HistoryContext = createContext({} as IHistoryContext);

export const HistoryProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [history, setHistory] = useState<History[]>([]);

  useEffect(() => {
    if (user.user) {
      setHistory((history) =>
        history[history.length - 1]?.pathname === pathname
          ? history
          : [...history, { pathname }]
      );
    } else {
      setHistory([]);
    }
  }, [user.user, pathname]);

  const handleBackHistory = useCallback(() => {
    const i = history.length - 2;
    const last = history[i];
    if (last?.pathname && last?.pathname !== "/") {
      setHistory((history) => history.slice(0, i));
      setTimeout(() => {
        navigate(last.pathname);
      }, 300);
    } else {
      setHistory([]);
      setTimeout(() => {
        navigate("/carteira");
      }, 300);
    }
  }, [navigate, history]);

  return (
    <HistoryContext.Provider value={{ history, handleBackHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
