import { Col, Row, Typography } from "antd";
import { ExteriorModalContainer } from "./styles";
import { formatCurrency, replaceDotByComma } from "../../../utils";

export enum ExteriorOperationType {
  TRADE = "TRADE",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
}

interface ExteriorModalContentProps {
  asset: any;
}

export const InterestModal = ({ asset }: ExteriorModalContentProps) => {
  return (
    <ExteriorModalContainer>
      <Row gutter={[16, 8]} align="middle" justify="space-between">
        <Col span="24">
          <Typography.Text strong className="exterior-content-title">
            {asset.type === "DIVIDEND" ? "Dividendos" : "Juros"} recebidos
          </Typography.Text>
        </Col>
        <Col span={14}>Valor bruto:</Col>
        <Col span={10}>
          <strong>{formatCurrency(asset.valor, "US$ ")}</strong>
        </Col>
        <Col span={14}>Taxa de corretagem:</Col>
        <Col span={10}>
          <strong>{formatCurrency(asset.fee, "US$ ")}</strong>
        </Col>
        <Col span={14}>Data de liquidação:</Col>
        <Col span={10}>
          <strong>{asset.data}</strong>
        </Col>
        <Col span={14}>Cotação dólar:</Col>
        <Col span={10}>
          <strong>{replaceDotByComma(asset.cambioVenda)}</strong>
        </Col>
        <Col span={24}>
          <div className="exterior-capital-gain">
            Rendimento total:&nbsp;&nbsp;&nbsp;
            <strong>{formatCurrency(asset.rendimentoAplicacao)}</strong>
          </div>
        </Col>
      </Row>
    </ExteriorModalContainer>
  );
};
