import styled from "styled-components";
import { Row } from "antd";

export const CardContent = styled.div`
  display: flex;
  background-color: transparent;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 115px;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;

  .full-container {
    margin: auto;

    .card-number {
      display: flex;
    }
  }
`;

export const NewCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  h3 {
    color: var(--ant-primary-color);
  }
`;

export const Content = styled(Row)`
  margin-top: 4%;

  .list-cards-content {
    height: 400px;
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
  }

  .rccs {
    transform: scale(55%);
  }
`;
