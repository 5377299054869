import clsx from "clsx";
import moment from "moment";
import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsCalendar3, BsDownload } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlineDownload,
  AiOutlineLock,
} from "react-icons/ai";
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import apis from "../../services/apis";
import apiBolsa from "../../services/apiBolsa";
import { Page } from "../../constants/brokers";
import AntButton from "../../components/Button";
import HandleTag from "../../services/handleTag";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { PaymentModal } from "../../components/PaymentModal";
import { Container, Content, BolsaOperations } from "./styles";
import { historicoVendasEmptyText, yearConfigPL4173 } from "../../constants/darf";
import { IntegrationModal } from "../Darf/Components/IntegrationModal";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { DatePickerExtraFooter } from "../Darf/Components/DatePickerExtraFooter";
import {
  DatePickerMonthCell,
  YearResume,
} from "../Darf/Components/DatePickerMonthCell";
import {
  DarfModal,
  DarfResultDescriptions,
  minDarfPrice,
  maxDarfPrice,
  IDarfBolsa,
  defaultDarfBolsa,
  DarfBolsaFormItemRows,
  PrejuFormItemRows,
  YearResumeStatusFromBackEnum,
  DarfBolsaTabs,
  IrrfFormItemRows,
  CorretagemFormItemRows,
} from "../../constants/darfBolsa";
import {
  antDatePickerLocale,
  download,
  errorMessage,
  formatCurrency,
  getZeroBuyPrice,
  isMobile,
  monthsExtended,
  numberToPercentageWallet,
} from "../../utils";
import { handleDownloadSheet } from "../../utils/handleDownloadSheet";
import { ModalZeroBuyPrice } from "../../components/ModalZeroBuyPrice";

interface DarfBolsaProps {
  item: Page;
  view?: boolean;
  viewEdit?: boolean;
  closeModal?: () => void;
  darf?: {
    id: string;
    fullPath?: string;
  };
  date?: {
    year?: number;
    month?: number;
  };
  darfBolsaRef: React.MutableRefObject<any>;
}

export const DarfBolsa: React.FC<DarfBolsaProps> = ({
  view,
  item,
  date,
  darf,
  viewEdit,
  closeModal,
  darfBolsaRef,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { oldPositionList } = useOldTransactions();
  const { currentBroker, currentPage } = useBroker();
  const {
    user,
    showUserPlanModal,
    userPlanModal,
    hasPlan: hasPremiumPlan,
    hasPermissionGeneral,
  } = useAuth();
  
  const today = new Date();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const [hasItems, sethasItems] = useState(false);
  const maxPixPayment = 10000;
  const maxCreditCardPayment = 10000;

  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
      ? today.getMonth() - 1
      : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
      ? today.getFullYear()
      : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;
  const currentDarfDate = new Date(year, month, 15)
  let dateAllowedOnBasic = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  if(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt) {
    dateAllowedOnBasic = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt);
    dateAllowedOnBasic.setMonth(dateAllowedOnBasic.getMonth() - 2)
  }
  const hideOnBasic = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime() && user.user?.userPlanInfoVelotax?.type?.includes('BASIC'))
  const hasPlan = (hasPremiumPlan || hasPermissionGeneral) && !hideOnBasic;

  const [asset, setAsset] = useState<any>();
  const [, setYear] = useState(initialYear);
  const [, setMonth] = useState(initialMonth);
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [darfModal, setDarfModal] = useState<any>();
  const [helpModal, setHelpModal] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [dataToEditPreju, setDataToEditPreju] = useState();
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [paymentData, setPaymentData] = useState<IDarfBolsa>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState<IDarfBolsa>(defaultDarfBolsa);
  const [buffer, setBuffer] = useState<Array<any>>();
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [editedAccumulatedTax, setEditedAccumulatedTax] = useState(false);
  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);
  const [showEditPrejuConfirmation, setShowEditPrejuConfirmation] =
    useState<boolean>(false);

  useImperativeHandle(darfBolsaRef, () => {
    return {
      editedAccumulatedTax,
      setEditedAccumulatedTax,
      handleDarf: (justSave?: boolean) => {
        handleDarf(justSave);
      },
    };
  });

  const isAntesTerceiroDiaUtil = () => {
    const today = moment();

    // Verifica se a data de hoje é um sábado
    if (today.isoWeekday() === 6) {
      return false;
    }

    // Verifica se a data de hoje é anterior ao terceiro dia útil
    const firstDayOfMonth = moment(today).startOf('month');
    let businessDaysCount = 0;

    while (businessDaysCount < 2) {
      if (firstDayOfMonth.isoWeekday() < 6) {
        businessDaysCount++;
      }

      firstDayOfMonth.add(1, 'day');
    }

    return today.isBefore(firstDayOfMonth);
  }

  const thirdBusinessDayAndMonth = (item: any) => {
    if (disableCurrentMonth(item))
      return { disabled: true, title: "Aguarde o encerramento do mês para pagar seu DARF" }
    else if (item.month + 1 == currentMonth && item.year == currentYear && isAntesTerceiroDiaUtil())
      return { disabled: true, title: "Aguarde o terceiro dia útil deste mês para pagar seu DARF" }
    else return { disabled: false, title: "" }
  }

  const hideOldPositionsText =
    oldPositionList?.length < 1 || oldPositionList === null;

  const valorPrincipal = Math.max(
    0,
    Number(
      (data.impostoComumFinal || 0) +
      (data.impostoDTFinal || 0) +
      (data.impostoFIIFinal || 0)
    ) +
    (Number(data["impostoAcumulado"] || 0) - Number(data["irrfTotal"] || 0))
  );
  const impostoCharge = useMemo(() => {
    const additionalTax = (data.multaAux ?? 0) + (data.jurosAux ?? 0)
    return (
      valorPrincipal +
      (valorPrincipal < minDarfPrice
        ? 0
        : additionalTax)
    );
  }, [data, valorPrincipal]);

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];
  const prejuizoFeature = item.features[3];
  const irrfFeature = item.features[4];

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const handleLabelButton: ReactNode = useMemo(() => {
    // if (!userPlanInfoStatus) return "Assinar Plano Emissão de DARF";

    if ((data?.impostoTotalFinal || 0) <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Emitir novo DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.impostoTotalFinal, taxFeature?.buttonLabel, emitted]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch((err) => {
            message.error(err.response?.data?.message || errorMessage);
          })
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= moment(`2020-01-01`).get("year") &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }
            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [yearResumeFeature, yearResume, view, currentPage]
  );

  const getTaxes = useCallback(
    (
      nAtualizarDarfsPassadas?: boolean,
      nAtualizarIrrf?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean,
      atualizarTotalmente?: boolean,
      atualizouCorretagem?: boolean
    ) => {
      (!hasPlan || !view) && setLoading(true);
      (!hasPlan || !view) &&
        (currentPage?.api || apis)
          .get(transactionFeature.apiUrl, {
            params: {
              month: month + 1,
              year,
              nAtualizarDarfsPassadas: nAtualizarDarfsPassadas || false,
              nAtualizarIrrf: nAtualizarIrrf || false,
              atualizouImpostoAcumulado: atualizouImpostoAcumulado || false,
              nAtualizarPrejuizosAcumulados:
                nAtualizarPrejuizosAcumulados || false,
              atualizarTotalmente: atualizarTotalmente || false,
              atualizouCorretagem: atualizouCorretagem || false,
            },
          })
          .then((response) => {
            if (response.data.memoriaCalculo) {
              setData({
                ...response.data,
                multa: response.data.multaAux,
                juros: response.data.jurosAux,
                impostoDevido: response.data.impostoTotal,
                memoriaCalculo: response.data.memoriaCalculo.map(
                  (memoCalc: any) => ({
                    ...memoCalc,
                    date: moment(memoCalc.date.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    ),
                    originalDate: memoCalc.date,
                    market:
                      memoCalc.market === "Opção de Compra" ||
                        memoCalc.market === "Opção de Venda"
                        ? "Mercado de opções"
                        : memoCalc.market,
                  })
                ),
              });
            }
            // setNotAuthorized(false);
          })
          .catch((err) => {
            setData(defaultDarfBolsa);
            if (err?.response?.data === "NOT_AUTHORIZED") {
              // setNotAuthorized(true);
              setShowNotAuthorizedModal(true);
            } else {
              // setNotAuthorized(false);
              message.error(errorMessage);
            }
          })
          .finally(() => setLoading(false));
    },
    [month, year, transactionFeature, view, hasPlan, currentPage]
  );

  const handleDarf = (justSave?: boolean) => {
    setEmitting(true);
    onCloseDarfModal();
    (currentPage?.api || apis)
      .post(taxFeature.apiUrl, {
        ...data,
        memoriaCalculo: [],
        transactions: data.memoriaCalculo,
        totalImpostoDevido: data.impostoTotalFinal,
        impostoDevido: data.impostoTotal,
        juros: data.jurosAux,
        multa: data?.multaAux,
        year,
        month: month + 1,
        name: user.user.name,
        insertPix: true,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        justSave,
        payed: false,
      })
      .then((res) => {
        setData((data) => ({ ...data, emitted: true }));
        getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        setEmitting(false);
        closeModal?.();
        if (!justSave) {
          message.success("O DARF foi enviado para o seu e-mail");
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoAcumulado:
          (data.impostoTotal ?? 0) + (data.impostoAcumulado ?? 0),
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
        getTaxes(false, false, false, true);
        message.success("Dados salvos");
        closeModal?.();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const onCloseDarfModal = () => {
    setDarfModal(undefined);
  };

  const handleOpenDarfModal = () => {
    setDarfModal(DarfModal(emitted, onCloseDarfModal, () => handleDarf()));
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    setAsset({
      ...item,
      id: item._id,
      feeB3: item.fee,
      price: item.sellPrice,
      avgBuyPrice: item.buyPrice,
      data: moment.utc(item.date.toLocaleString()).format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(transactionFeature.apiUrl, {
        ...asset,
        operation: "V",
        avgSellPrice: asset.price,
        totalValue: asset.price * asset.quantity,
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${asset.id}`, {
        ...asset,
        operation: "V",
        avgSellPrice: asset.price,
        totalValue: asset.price * asset.quantity,
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset.id ?? asset._id}`, {
        params: {
          year,
          month,
        },
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  /* Manipular as tabs de imposto no mobile */
  const [tab, setTab] = useState(1);
  const bolsaOperationsRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    setTab((tab) => tab + 1);
    const span = document.querySelector("div.sub-header > span.show");
    bolsaOperationsRef.current?.scrollBy({ left: span?.clientWidth ?? 155 });
  };

  const handlePrev = () => {
    setTab((tab) => tab - 1);
    const span = document.querySelector("div.sub-header > span.show");
    bolsaOperationsRef.current?.scrollBy({ left: -(span?.clientWidth ?? 155) });
  };
  /* Fim da manipulação das tabs de imposto no mobile */

  /* Editar prejuízo acumulado */
  const [resetedPreju, setResetedPreju] = useState(false);
  const [showPrejuModal, setShowPrejuModal] = useState(false);
  const [showCorretagemModal, setShowCorretagemModal] = useState(false);
  const [loadingResetPreju] = useState(false);
  const [loadingEditPreju, setLoadingEditPreju] = useState(false);
  const [ignoreZeroBuyPrice, setIgnoreZeroBuyPrice] = useState(false);
  const [dataZeroBuyPrice, setDataZeroBuyPrice] = useState<any[]>([]);
  const [modalZeroBuyPrice, setModalZeroBuyPrice] = useState(false);

  const preju = useMemo(
    () => ({
      userPrejuizoComum: resetedPreju
        ? data.prejuizoComum
        : data.userPrejuizoComum ?? data.prejuizoComum,
      userPrejuizoDayTrade: resetedPreju
        ? data.prejuizoDayTrade
        : data.userPrejuizoDayTrade ?? data.prejuizoDayTrade,
      userPrejuizoFII: resetedPreju
        ? data.prejuizoFII
        : data.userPrejuizoFII ?? data.prejuizoFII,
    }),
    [data, resetedPreju]
  );

  const custoCorretagem = useMemo(
    () => ({
      userCustoCorretagemComum: data.userCustoCorretagemComum || 0,
      userCustoCorretagemDT: data.userCustoCorretagemDT || 0,
      userCustoCorretagemFII: data.userCustoCorretagemFII || 0,
    }),
    [data]
  );

  // const handleResetPreju = () => {
  //   setResetedPreju(true);
  // };

  // const handleResetPreju = () => {
  //   setLoadingResetPreju(true);
  //   (currentPage?.api || apis)
  //     .put(`${prejuizoFeature.apiUrl}/update-all?month=${month + 1}&year=${year}`)
  //     .then(() => {
  //       message.success('Prejuízos resetados com sucesso.');
  //       getTaxes(false, false, false, true, true);
  //     })
  //     .catch(() => {
  //       message.error("Não foi possível resetar os valores");
  //     })
  //     .finally(() => {
  //       setLoadingResetPreju(false);
  //       handleClosePrejuModal();
  //     });
  // };

  const handleEditPreju = () => {
    setResetedPreju(false);
    setShowPrejuModal(true);
  };

  const handleEditPrejuData = (preju: any) => {
    setDataToEditPreju(preju);
    setShowEditPrejuConfirmation(true);
  };

  const handleEditCorretagem = () => {
    setShowCorretagemModal(true);
  };

  const handleClosePrejuModal = () => {
    setShowPrejuModal(false);
  };

  const handleCloseCorretagemModal = () => {
    setShowCorretagemModal(false);
  };

  const editPreju = () => {
    setLoadingEditPreju(true);
    setShowEditPrejuConfirmation(false);

    (currentPage?.api || apis)
      .put(
        `${prejuizoFeature.apiUrl}?month=${month + 1}&year=${year}`,
        dataToEditPreju
      )
      .then((res) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      })
      .catch(() => {
        message.error(errorMessage);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      });
  };

  const editCorretagem = (data: any) => {
    handleCloseCorretagemModal();
    (currentPage?.api || apis)
      .put(
        `/xpinvestimentos/custoCorretagem?month=${month + 1}&year=${year}`,
        data
      )
      .then((res) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true, false, true);
      })
      .catch(() => {
        message.error(errorMessage);
      });
  };
  /* Fim do editar prejuízo acumulado */

  /* Editar IRRF */
  const [showIrrfModal, setShowIrrfModal] = useState(false);

  const irrf = useMemo(
    () => ({
      userIrrfComum: data.irrfComum,
      userIrrfDayTrade: data.irrfDayTrade,
      userIrrfFII: data.irrfFII,
      userIrrfTotal: data.irrfTotal,
    }),
    [data]
  );

  // const handleResetIrrf = () => {
  //   setResetedIrrf(true);
  // };

  const handleEditIrrf = () => {
    setShowIrrfModal(true);
  };

  const handleCloseIrrfModal = () => {
    setShowIrrfModal(false);
  };

  const editIrrf = (data: any) => {
    handleCloseIrrfModal();
    (currentPage?.api || apis)
      .put(`${irrfFeature.apiUrl}?month=${month + 1}&year=${year}`, data)
      .then((response) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true);
      })
      .catch(() => {
        message.error(errorMessage);
      });
  };
  /* Fim do editar prejuízo acumulado */

  useEffect(() => {
    // if (month === 0) {
    getTaxes(true, true, false, true, false);
    // } else {
    //   getTaxes(false, false, false, false, true);
    // }
  }, [getTaxes, month, year]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.impostoTotalFinal, data, month, user.user.name, year]);

  useEffect(() => {
    if (data?.memoriaCalculo?.length > 0) sethasItems(!hideOnBasic);
    setBuffer(data?.buffer?.data);
  }, [data, hideOnBasic]);

  const handlePlanModal = () => {
    navigate('/planos')
  };

  useEffect(()=> {
    if (ignoreZeroBuyPrice) handleEventDarfButton();
  }, [ignoreZeroBuyPrice])

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    if (!ignoreZeroBuyPrice) {
      const hasZeroBuyPrice = await getZeroBuyPrice(data?.memoriaCalculo);
      if (hasZeroBuyPrice.length > 0) {
        setModalZeroBuyPrice(true);
        setDataZeroBuyPrice(hasZeroBuyPrice);
        return;
      }
    }

    //@ts-ignore
    !retPlanInfo.data.active && !retPlanInfo.data.cupomDueDate
      ? handlePlanModal()
      : handleOpenPaymentModal();
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    try {
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      retPlanInfo.data.active || hasPermissionGeneral
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  const downloadAction = (record: any) => {
    apiBolsa.get(`/darf/download/${record._id}`).then((res) => {
      if (res.data.url) {
        download(res.data.url);
      }
    });
  };

  const calcReturn = (
    sellPrice: number,
    buyPrice: number,
    quantity: number,
    fee: number
  ) => {
    
    const qtt = quantity ? quantity : 1;
    const ret =
      sellPrice && buyPrice
        ? (sellPrice * qtt - (fee || 0)) / (buyPrice * qtt) - 1
        : 0;
    return (
      <strong
        style={{
          color: `${ret >= 0
            ? "var(--velotax-green-amount)"
            : "var(--velotax-red-prejuizo)"
            }`,
        }}
      >
        {ret ? numberToPercentageWallet(ret) : "-"}
      </strong>
    );
  };

  const disableCurrentMonth = (item: any) =>
    item.year === currentYear && item.month === currentMonth;

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      <Content className={clsx("first", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement!}
                  renderExtraFooter={() =>
                    yearResumeFeature.disabled ? (
                      <></>
                    ) : (
                      <DatePickerExtraFooter />
                    )
                  }
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>

      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {hasPlan && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: "-32px" }}
                  onClick={() => downloadAction(darf)}
                  startIcon={
                    <BsDownload
                      size={16}
                      style={{
                        fill: "var(--ant-primary-color)",
                      }}
                    />
                  }
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}
          <BolsaOperations ref={bolsaOperationsRef}>
            {(!hasPlan || hideOnBasic) && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal?.();
                  }}
                >
                  PREMIUM
                </Button>
              </div>
            )}
            {DarfBolsaTabs(data, {
              handleEditPreju,
              handleEditCorretagem,
              view,
              month,
              year,
            }).map((container, rowIndex) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item, colIndex) => (
                  <span
                    key={colIndex}
                    className={clsx(
                      colIndex === 0 || tab === colIndex ? "show" : "",
                      {
                        "no-plan": !hasPlan && rowIndex > 0 && colIndex > 0,
                      }
                    )}
                  >
                    {rowIndex === 0 && (
                      <AntButton
                        type="text"
                        icon={<GrFormPrevious />}
                        onClick={
                          !hasPlan || colIndex === 0 || tab === 1
                            ? undefined
                            : handlePrev
                        }
                        style={{
                          visibility:
                            !hasPlan || colIndex === 0 || tab === 1
                              ? "hidden"
                              : "visible",
                        }}
                      />
                    )}
                    {!hasPlan && rowIndex > 0 && colIndex > 0
                      ? formatCurrency(13443)
                      : isMobile() && !hasPlan && rowIndex === 0
                        ? ""
                        : item.content}
                    {rowIndex === 0 && (
                      <AntButton
                        type="text"
                        icon={<GrFormNext />}
                        onClick={
                          !hasPlan || colIndex === 0 || tab === 3
                            ? undefined
                            : handleNext
                        }
                        style={{
                          visibility:
                            !hasPlan || colIndex === 0 || tab === 3
                              ? "hidden"
                              : "visible",
                        }}
                      />
                    )}
                  </span>
                ))}
              </div>
            ))}
          </BolsaOperations>

          {/* <div className="edition-button-corretagem">
            <button onClick={handleEditCorretagem}>
              {view ? <BsEye /> : <FaRegEdit />}
              <span>
                {view
                  ? "Exibir taxas de corretagem"
                  : "Inserir soma das taxas de corretagem (opcional)"}
              </span>
            </button>
            {!view && (
              <button onClick={() => setHelpModal(CorretagemModalProps)}>
                <InfoCircleOutlined />
              </button>
            )}
          </div> */}

          <br />

          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!hasPlan ? ["1"] : []}
            {...(!hasPlan ? { collapsible: "disabled" } : {})}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {hasItems && (
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => handleDownloadSheet(buffer, "Vendas")}
                  style={{
                    alignItems: "center",
                    fontSize: "13px",
                    paddingRight: "0",
                    justifyContent: "end",
                    backgroundColor: "transparent",
                    color: "#fbc105",
                  }}
                  startIcon={
                    <AiOutlineDownload
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                        fill: "#fbc105",
                      }}
                    />
                  }
                >
                  Baixar planilha
                </Button>
              )}
              {!view && (
                <Button
                  color="secondary"
                  variant="contained"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  startIcon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: historicoVendasEmptyText,
                }}
                dataSource={
                  user?.user?.cpf === '01268936324' ?
                    data.memoriaCalculo?.sort(
                  (a, b) =>
                    new Date(b.originalDate).getTime() -
                    new Date(a.originalDate).getTime()
                    ).slice(0, 5) :
                    hideOnBasic ? [] :
                    data.memoriaCalculo?.sort(
                      (a, b) =>
                        new Date(b.originalDate).getTime() -
                        new Date(a.originalDate).getTime()
                    )}
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      view
                        ? []
                        : [
                          <AntButton
                            type="text"
                            onClick={() => handleEdit(item, index)}
                            icon={<FaRegEdit size={16} />}
                          />,
                          <AntButton
                            type="text"
                            onClick={() => handleRemove(item, index)}
                            icon={<AiOutlineDelete size={16} />}
                          />,
                        ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={<strong>{item.code}</strong>}
                        description={
                          <div className="list-description">
                            <div>
                              Data:{" "}
                              {moment(item.date, [
                                "DD/MM/YYYY",
                                "YYYY-MM-DD",
                              ]).format("DD/MM/YYYY")}
                            </div>
                            <div>Quantidade: {item.quantity}</div>
                            {view && (
                              <>
                                <div>
                                  Custo médio de compra:{" "}
                                  <strong>
                                    {formatCurrency(item.buyPrice)}
                                  </strong>
                                </div>
                                <div>
                                  Preço de venda:{" "}
                                  <strong>
                                    {formatCurrency(item.sellPrice)}
                                  </strong>
                                </div>
                              </>
                            )}
                            <div>
                              Ganho:{" "}
                              <strong>
                                {formatCurrency(item.capitalGain)}
                              </strong>
                            </div>
                            <div>
                              Retorno:{" "}
                              {calcReturn(
                                item?.sellPrice,
                                item?.buyPrice,
                                item?.quantity,
                                item?.fee
                              )}
                            </div>
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
              {
                user?.user?.cpf === '01268936324' &&
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => handleDownloadSheet(buffer, "Vendas")}
                style={{
                  alignItems: "center",
                  fontSize: "13px",
                  paddingRight: "0",
                  justifyContent: "end",
                  backgroundColor: "transparent",
                  color: "#fbc105",
                }}
                startIcon={
                  <AiOutlineDownload
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "4px",
                      fill: "#fbc105",
                    }}
                  />
                }
              >
                Baixar lista completa de operações de vendas.
              </Button>
              }
            </Collapse.Panel>
          </Collapse>

          {hasPlan && <br />}

          {hasPlan && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(impostoCharge)
                )}
              </Typography.Title>
            </div>
          )}

          {hasPlan && (
            <Descriptions size="small" bordered layout="horizontal">
              {DarfResultDescriptions.map((description) => {
                return (
                  <Descriptions.Item
                    span={24}
                    key={description.id}
                    label={description.label(setHelpModal)}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.Component ? (
                      <description.Component
                        data={data}
                        year={year}
                        view={view}
                        month={month}
                        disabled={!view}
                        loading={loading}
                        getTaxes={getTaxes}
                        setDataBolsa={setData}
                        url={transactionFeature.apiUrl}
                        valorPrincipal={valorPrincipal}
                        setEditedAccumulatedTax={setEditedAccumulatedTax}
                        handleEdit={
                          description.id === "irrfTotal"
                            ? handleEditIrrf
                            : undefined
                        }
                      />
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarfBolsa] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )}

          {!view && (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                className="column-reverse"
                style={{ marginTop: "16px" }}
              >
                {!(month === currentMonth && year === currentYear) &&
                  valorPrincipal < minDarfPrice &&
                  data?.memoriaCalculo?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês.
                          </>
                        }
                      >
                        <Button
                          fullWidth
                          size="large"
                          color="secondary"
                          variant="contained"
                          disabled={loading || emitting}
                          startIcon={emitting && <LoadingOutlined />}
                          onClick={() => {
                            handleRegularize(true);
                          }}
                        >
                          Salvar sem emitir DARF
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                {valorPrincipal >= minDarfPrice && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        thirdBusinessDayAndMonth(date).title
                      }
                    >
                      <Button
                        fullWidth
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleEventDarfButton()}
                        startIcon={
                          (emitting || loadingDarfButton) && <LoadingOutlined />
                        }
                        disabled={
                          loading ||
                          emitting ||
                          loadingDarfButton ||
                          thirdBusinessDayAndMonth(date).disabled
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              {!(month === currentMonth && year === currentYear) && (
                <Row
                  justify="end"
                  align="middle"
                  gutter={[16, 16]}
                  style={{ marginTop: "16px" }}
                >
                  <Col>
                    <Button
                      size="small"
                      variant="text"
                      color="secondary"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      startIcon={emitting && <LoadingOutlined />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}

          {!view && (
            <div className="juridic-messages-container">
              <div className="message">
                {!hideOldPositionsText && (
                  <p>
                    <QuestionCircleOutlined /> Você tinha investimentos em
                    31/12/2019 e ainda não preencheu o seu custo médio?{" "}
                    <span
                      onClick={() =>
                        navigate(`/${currentBroker.path}/bolsa-insert-manual`)
                      }
                      style={{
                        cursor: "pointer",
                        color: "var(--ant-primary-color)",
                      }}
                    >
                      Clique aqui.
                    </span>
                  </p>
                )}
              </div>
              {/* <div className="message">
                <p>
                  ({hideOldPositionsText ? 1 : 2}) A Receita Federal admite a
                  dedução dos custos de corretagem na apuração do ganho líquido
                  em operações de renda variável. Entretanto, não há
                  obrigatoriedade do usufruto do benefício da dedução por parte
                  dos investidores (Instrução Normativa RFB nº 1.585, de 31 de
                  agosto de 2015, art. 56, § 3º). As informações advindas
                  somente da integração com a B3 não consideram o benefício da
                  dedução dos custos de corretagem da XP, incluindo somente as
                  tarifas de liquidação, registro, termo/opções e emolumentos da
                  B3. Caso deseje descontar os seus custos de corretagem dos
                  ganhos tributáveis, você pode adicionar manualmente as taxas
                  de corretagem em cada operação no campo “Taxas” ou carregar
                  suas
                  <span
                    onClick={() =>
                      navigate(
                        item.settings?.integrationPath ??
                          `/${currentBroker.path}/integration`
                      )
                    }
                    style={{
                      cursor: "pointer",
                      color: "var(--ant-primary-color)",
                    }}
                  >
                    {" "}
                    notas de corretagem.
                  </span>
                </p>
              </div> */}
              {/* <div className="message"> */}
              {/* <p>
              ({hideOldPositionsText ? 2 : 3}) As taxas apresentadas incluem
              somente as tarifas de liquidação, registro, termo/opções e
              emolumentos da B3. Caso deseje, você pode adicionar manualmente as
              taxas de corretagem em cada operação.
            </p> */}
              {/* </div> */}
              {/* <div className="message">
                <p>
                  ({hideOldPositionsText ? 2 : 3}) Para preencher
                  automaticamente os seus prejuízos acumulados, você deve salvar
                  os meses na ordem do mais antigo para o mais recente.
                </p>
              </div> */}
              {/* <div className="message">
                    <p>
                      ({hideOldPositionsText ? 3 : 4}) O Usuário é
                      exclusivamente responsável pela conferência e validação
                      das informações utilizadas na apuração do imposto devido,
                      conforme{" "}
                      <a
                        rel="noreferrer"
                        href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Velotax%20v1.pdf"
                        target="_blank"
                      >
                        Termos e Condições Gerais de Uso.
                      </a>
                    </p>
                  </div> */}
            </div>
          )}
        </Space>
      </Content>
      <Modal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </Modal>

      <FormModal
        add={editPreju}
        edit={() => { }}
        rows={[]}
        itemToEdit={null}
        visibility={showEditPrejuConfirmation}
        saveButtonText={"Sim"}
        title={"Editar prejuízos"}
        onCancel={() => setShowEditPrejuConfirmation(false)}
      >
        <div
          style={{
            color: "var(--velotax-font-color-dark)",
          }}
        >
          Você tem certeza que deseja editar os valores calculados de prejuízos
          acumulados?
        </div>
      </FormModal>

      <Modal
        visible={darfModal}
        title={darfModal?.title}
        footer={darfModal?.footer}
        onCancel={onCloseDarfModal}
        zIndex={1001}
      >
        {darfModal?.content}
      </Modal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        rows={DarfBolsaFormItemRows(month, year)}
        title={asset ? "Editar ativo" : "Adicionar ativo"}
      />
      <FormModal
        add={() => { }}
        edit={(data: any) => handleEditPrejuData(data)}
        itemToEdit={preju}
        loading={loadingEditPreju}
        disableButtons={loadingResetPreju || loadingEditPreju}
        rows={PrejuFormItemRows(loadingResetPreju || loadingEditPreju)}
        visibility={showPrejuModal}
        onCancel={handleClosePrejuModal}
        title="Editar prejuízos a compensar"
      // preFormChildren={
      //   <Button
      //     size="medium"
      //     color="secondary"
      //     variant="outlined"
      //     onClick={handleResetPreju}
      //     disabled={loadingResetPreju || loadingEditPreju}
      //     startIcon={loadingResetPreju && <LoadingOutlined />}
      //     style={{ display: "flex", margin: "0 0 16px auto" }}
      //   >
      //     Resetar valores
      //   </Button>
      // }
      />

      <FormModal
        add={() => { }}
        edit={editCorretagem}
        itemToEdit={custoCorretagem}
        rows={CorretagemFormItemRows(!!view)}
        visibility={showCorretagemModal}
        onCancel={handleCloseCorretagemModal}
        title="Taxas de corretagem"
        hideFooter={view}
      >
        {/* <Divider />
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Typography>
              Caso altere algum desses valores, você pode voltar aos valores
              calculados automaticamente pelo sistema (clique em aplicar e
              salvar):
            </Typography>
          </Col>
          <Col xs={24} sm={14}>
            <Row>
              <Col span={16}>Prejuízo Comum:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoComum ?? 0)}</Col>
              <Col span={16}>Prejuízo Day Trade:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoDayTrade ?? 0)}</Col>
              <Col span={16}>Prejuízo FII:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoFII ?? 0)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={10}>
            <Button onClick={handleResetPreju} style={{ marginLeft: "auto" }}>
              Aplicar
            </Button>
          </Col>
        </Row> */}
      </FormModal>
      <FormModal
        add={() => { }}
        edit={editIrrf}
        itemToEdit={irrf}
        rows={IrrfFormItemRows}
        visibility={showIrrfModal}
        onCancel={handleCloseIrrfModal}
        title="Editar IRRF"
      >
        {/* <Divider />
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Typography>
              Caso altere algum desses valores, você pode voltar aos valores
              calculados automaticamente pelo sistema (clique em aplicar e
              salvar):
            </Typography>
          </Col>
          <Col xs={24} sm={14}>
            <Row>
              <Col span={16}>IRRF Comum:</Col>
              <Col span={8}>{formatCurrency(data.irrfComum ?? 0)}</Col>
              <Col span={16}>IRRF Day Trade:</Col>
              <Col span={8}>{formatCurrency(data.irrfDayTrade ?? 0)}</Col>
              <Col span={16}>IRRF FII:</Col>
              <Col span={8}>{formatCurrency(data.irrfFII ?? 0)}</Col>
              <Col span={16}>IRRF Total:</Col>
              <Col span={8}>{formatCurrency(data.irrfTotal ?? 0)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={10}>
            <Button onClick={handleResetIrrf} style={{ marginLeft: "auto" }}>
              Aplicar
            </Button>
          </Col>
        </Row> */}
      </FormModal>
      <PaymentModal
        fromDarf
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        closeDarfModal={closeModal}
        onCancel={handleOpenPaymentModal}
        maxCCValue={maxCreditCardPayment}
        disablePixOption={(data?.impostoTotalFinal || 0) > maxPixPayment}
        paymentData={{ ...paymentData, impostoTotalFinal: impostoCharge }}
        disableCreditCardOption={
          (data?.impostoTotalFinal || 0) > maxCreditCardPayment
        }
        callDarf={() => {
          HandleTag("64");
          handleOpenDarfModal();
        }}
      />
      <DeleteConfirmationModal
        showToast={false}
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
      <IntegrationModal
        visible={showNotAuthorizedModal}
        onCancel={() => setShowNotAuthorizedModal(false)}
      />
      {!view && !viewEdit && (
        <BackButton to="/xpinvestimentos/bolsa" className="darf-bolsa" />
      )}
      <ModalZeroBuyPrice
        visible={modalZeroBuyPrice}
        title={'Atenção'}
        dataZeroBuyPrice={dataZeroBuyPrice}
        setIgnoreZeroBuyPrice={setIgnoreZeroBuyPrice}
        setModalZeroBuyPrice={setModalZeroBuyPrice}
      >
      </ModalZeroBuyPrice>
    </Container>
  );
};
