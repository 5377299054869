// Retorno: {calcReturn(item.valor ?? item.sellPrice_BRL, item.valueOriginBRA ?? item.buyPrice_BRL, item.quantidade, item.fee ?? item.fee_BRL, item.ganho ?? item.varTotalBRL)}

export function useDarfExterior() {
    const gainKeys = ['ganho', 'varTotalBRL'];
    const sellPriceKeys = ['valor', 'sellPrice_BRL'];
    const buyPriceKeys = ['valueOriginBRA', 'buyPrice_BRL'];
    const feeKeys = ['fee', 'fee_BRL'];

    function getByKey(item: any, keys: string[], isOldModel?: boolean) {
        if (isOldModel !== undefined) {
            if (isOldModel) {
                return { key: keys[0], value: item[keys[0]] ?? null };
            }
            return { key: keys[1], value: item[keys[1]] ?? null };
        }
        const k = keys.find((key) => item[key] !== null && item[key] !== undefined);
        const value = k ? item[k] : null;
        const key = k ?? keys[0];
        return { key, value };
    }

    function getGain(item: any, isOldModel?: boolean) {
        return getByKey(item, gainKeys, isOldModel)
    }

    function getSellPrice(item: any, isOldModel?: boolean) {
        return getByKey(item, sellPriceKeys, isOldModel)
    }

    function getBuyPrice(item: any, isOldModel?: boolean) {
        return getByKey(item, buyPriceKeys, isOldModel)
    }

    function getFee(item: any, isOldModel?: boolean) {
        return getByKey(item, feeKeys, isOldModel)
    }

    return { getBuyPrice, getGain, getSellPrice, getFee }
}