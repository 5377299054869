import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Typography, message } from "antd";
import { Card } from "../Exterior/Cards";
import { itensBolsa } from "./itensBolsa";
import { HomeContainer } from "../Home/styles";
import AntButton from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { UserPlanEnum } from "../../constants/plans";
import { download, errorMessage } from "../../utils";
import api from "../../services/apiBolsa";

export const Bolsa = () => {
  const navigate = useNavigate();
  const { user, hasPlan, showUserPlanModal, userPlanModal } = useAuth();
  const [loading, setLoading] = useState(false);
  const [infoModal, toggleModal] = useState(null);
  const [reportCount, setReportCount] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [reportIsReady, setReportIsReady] = useState(false);

  const userPlanInfoStatus = useMemo(
    () => user.user?.userPlanInfoVelotax,
    [user.user?.userPlanInfoVelotax]
  );

  const handlePlanModal = () => {
    navigate('/planos')
  };

  const handleAnualReport = async (id: string) => {
    const blockHandle =
      !userPlanInfoStatus.active &&
      !userPlanInfoStatus.type &&
      !(
        userPlanInfoStatus.type === UserPlanEnum.VELOTAX_MAIN_BASIC ||
        userPlanInfoStatus.type === UserPlanEnum.VELOTAX_MAIN_PRO ||
        userPlanInfoStatus.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE ||
        userPlanInfoStatus.type === UserPlanEnum.VELOTAX_MAIN_PRO_MONTH ||
        userPlanInfoStatus.type === UserPlanEnum.XP_BASIC ||
        userPlanInfoStatus.type === UserPlanEnum.XP_PRO ||
        userPlanInfoStatus.type === UserPlanEnum.XP_CONCIERGE ||
        userPlanInfoStatus.type === UserPlanEnum.XP_PRO_MONTH
      );
    if (blockHandle) {
      handlePlanModal();
    } else {
      toggleModal({ year: 2022 } as any);
    }
  };

  const downloadAction = (record: any) => {
    setDownloading(true);
    api
      .post("/darf/report/download/anual-report", {
        from: "xpinvestimentos",
        id: record._id,
        month: record.month,
        year: record.year,
      })
      .then((res) => {
        if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
        if (res.data.file) {
          message.success("Relatório enviado para o seu e-mail");
          // downloadPDF(res.data.file);
        }
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => {
        setDownloading(false);
        toggleModal(null);
      });
  };

  const checkProcessorAction = (record: any) => {
    setLoading(true);
    setReportIsReady(false);
    setReportCount(0);
    const reportCountInterval = setInterval(() => {
      setReportCount((n) => n + 1);
    }, 1500);
    let showToast = false;
    toggleModal(record);
    const itvl = setInterval(() => {
      api
        .get(`/darf/report/download/anual-report?year=${record.year}`)
        .then((res) => {
          const { data } = res;
          const { year, status } = data;
          const isProcessing = record.year === year && status === "processing";
          if (isProcessing && showToast) return;
          if (isProcessing && !showToast) {
            showToast = true;
            return message.success(
              `Estamos processando os dados para seu relatório anual de ${year}`
            );
          }
          clearInterval(reportCountInterval);
          const reportFinishCountInterval = setInterval(() => {
            setReportCount((n) => {
              if (n > 100) {
                clearInterval(reportFinishCountInterval);
                setLoading(false);
                setReportIsReady(true);
                clearInterval(itvl);
              }
              return n + 1;
            });
          }, 50);
        })
        .catch(({ response }) => {
          const { data, status } = response;
          if (status === 401)
            setTimeout(
              () => navigate("/xpinvestimentos/bolsa-integration"),
              2000
            );
          message.error(data?.message ?? errorMessage);
          setLoading(false);
          clearInterval(itvl);
        });
    }, 5000);
  };

  return (
    <HomeContainer>
      <div className="content bolsa">
        <div>
          <Typography.Title level={1}>Bolsa de valores</Typography.Title>
          <Typography.Text className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {itensBolsa.slice(0, 3).map((item) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={
                  (loading || downloading) && item.id === "anual-report" ? (
                    <LoadingOutlined />
                  ) : (
                    item.icon
                  )
                }
                onClick={
                  item.premium && (!hasPlan || user?.user?.userPlanInfoVelotax?.type?.includes('BASIC'))
                    ? () => handlePlanModal()
                    : item.id === "anual-report"
                      ? () => handleAnualReport(item.id)
                      : undefined
                }
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
                {!hasPlan || item.premium && (!hasPlan.premium && !hasPlan.concierge)
                      ? (
                        <AntButton
                        type="primary"
                        className="btn-premium"
                        icon={<AiOutlineLock size={18} />}
                        onClick={() => {
                          handlePlanModal();
                        }}
                      >
                        PREMIUM
                      </AntButton>
                      )
                      :
                      null}
              </Card>
            ))}
          </div>
        </div>
      </div>
      <Modal
        title="Informe de Rendimentos"
        visible={!!infoModal}
        closable={!loading && !downloading}
        maskClosable={!loading && !downloading}
        onCancel={() => {
          toggleModal(null);
        }}
        footer={
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col span={24}>
              <Button
                fullWidth
                size="large"
                color="secondary"
                variant="contained"
                startIcon={(loading || downloading) && <LoadingOutlined />}
                onClick={() => {
                  if (!loading && !downloading) {
                    if (reportIsReady) {
                      downloadAction(infoModal);
                    } else {
                      checkProcessorAction({ year: 2022 } as any);
                    }
                  }
                }}
              >
                {loading && reportCount <= 100 ? `${reportCount}% ` : ""}
                {downloading
                  ? "Baixando informe"
                  : loading
                    ? "Gerando informe"
                    : reportIsReady
                      ? "Baixar informe"
                      : "Gerar informe"}
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                fullWidth
                size="small"
                variant="text"
                color="secondary"
                disabled={loading || downloading}
                onClick={() => {
                  if (!loading && !downloading) {
                    navigate("/xpinvestimentos/bolsa-historic");
                  }
                }}
              >
                Ir para calculadora de DARF
              </Button>
            </Col>
          </Row>
        }
      >
      </Modal>
    </HomeContainer>
  );
};
