import { Col, Row, Typography } from "antd";
import { ExteriorModalContainer } from "./styles";
import { formatCurrency, replaceDotByComma } from "../../../utils";

export enum ExteriorOperationType {
  TRADE = "TRADE",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
}

interface ExteriorModalContentProps {
  asset: any;
}

export const ExteriorModalContent = ({ asset }: ExteriorModalContentProps) => {
  return (
    <ExteriorModalContainer>
      <Row gutter={[16, 8]} align="middle" justify="space-between">
        <Col span={24}>
          Quantidade: <strong>{asset.quantidade}</strong>
        </Col>
        <Col span="24">
          <Typography.Text strong className="exterior-content-title">
            {asset.type === ExteriorOperationType.TRADE
              ? "Compra"
              : asset.type === ExteriorOperationType.DEPOSIT
              ? "Depósito"
              : asset.type === ExteriorOperationType.WITHDRAW
              ? "Resgate"
              : "Compra"}
          </Typography.Text>
        </Col>
        <Col span={14}>Origem BRA:</Col>
        <Col span={10}>
          <strong>{formatCurrency(asset.valueOriginBRA, "US$ ")}</strong>
        </Col>
        <Col span={14}>Origem USA:</Col>
        <Col span={10}>
          <strong>{formatCurrency(asset.valueOriginEUA, "US$ ")}</strong>
        </Col>
        {asset.type === ExteriorOperationType.TRADE && (
          <Col span="24">
            <Typography.Text strong className="exterior-content-title">
              Venda
            </Typography.Text>
          </Col>
        )}
        {asset.type === ExteriorOperationType.TRADE && (
          <>
            <Col span={14}>Valor bruto:</Col>
            <Col span={10}>
              <strong>{formatCurrency(asset.valor, "US$ ")}</strong>
            </Col>
          </>
        )}
        <Col span={14}>Taxa de corretagem:</Col>
        <Col span={10}>
          <strong>{formatCurrency(asset.fee, "US$ ")}</strong>
        </Col>
        <Col span={14}>Data de liquidação:</Col>
        <Col span={10}>
          <strong>{asset.data}</strong>
        </Col>
        <Col span={14}>Cotação dólar:</Col>
        <Col span={10}>
          <strong>{replaceDotByComma(asset.cambioVenda)}</strong>
        </Col>
        {(asset.type === ExteriorOperationType.DEPOSIT ||
          asset.type === ExteriorOperationType.WITHDRAW) && (
          <>
            <Col span={14}>Cotação dólar Referência:</Col>
            <Col span={10}>
              <strong>{replaceDotByComma(asset.cambioReferencia.toFixed(4))}</strong>
            </Col>
          </>
        )}
        <Col span={24}>
          <div className="exterior-capital-gain">
            Ganho de capital:&nbsp;&nbsp;&nbsp;
            <strong>{formatCurrency(asset.type === "INTEREST" ? asset.ganho2 : asset.ganho)}</strong>
          </div>
        </Col>
      </Row>
    </ExteriorModalContainer>
  );
};
