import clsx from "clsx";
import DOMPurify from "dompurify";
import { BsX } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import { MdErrorOutline } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { IoChatbubblesOutline } from "react-icons/io5";
import { useCallback, useEffect, useRef, useState } from "react";
import { chatApi } from "../../services/chatApi";
import { ChatButton, ChatWindow } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { ReactComponent as VelotaxIcon } from "../../assets/velotax/veloicon.svg";

interface Msg {
  user: "velotax" | "user";
  text: string;
  error?: boolean;
}

interface APIChatMessage {
  userMessage: string;
  botResponse: string;
  formattedGptAnswer: string;
  createdAt: Date;
}

const KEY = "velochat.thread_id";
const KEY_TIMESTAMP = "velochat.timestamp";
const USER_MESSAGE_MAX_LENGTH = 200;
const defaultMsg: Msg = {
  user: "velotax",
  text: "Olá! Eu sou o assistente virtual da Velotax. Como posso te ajudar?",
};

export const ChatGPT = () => {
  const storage = localStorage;
  const { signed, user } = useAuth();
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [msgs, setMsgs] = useState<Msg[]>([defaultMsg]);

  chatApi.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

  const hide =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const disabled =
    !msg.replace(/\s/g, "").length || msg.length > USER_MESSAGE_MAX_LENGTH;

  const resetChat = () => {
    setMsg("");
    setMsgs([defaultMsg]);
    storage.removeItem(KEY);
    storage.removeItem(KEY_TIMESTAMP);
  };

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      chatBodyRef.current?.scrollTo({
        top: chatBodyRef.current?.scrollHeight,
      });
    }, 50);
  }, []);

  const after24H = useCallback(() => {
    try {
      const timestamp = storage.getItem(KEY_TIMESTAMP);
      if (
        timestamp &&
        new Date().getTime() >
          new Date(Number(timestamp)).getTime() + 24 * 60 * 60 * 1000
      ) {
        storage.removeItem(KEY);
        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
      return true;
    }
  }, [storage]);

  const loadMsgs = useCallback(async () => {
    const threadId = storage.getItem(KEY);
    if (!threadId) return;
    if (after24H()) return;
    try {
      setFirstLoading(true);
      const { data } = await chatApi.get<APIChatMessage[]>("/chat/user", {
        params: { threadId },
      });
      if (data?.length > 0) {
        setMsgs([
          defaultMsg,
          ...data.reduce(
            (acc, cur) => [
              ...acc,
              { user: "user", text: cur.userMessage } as Msg,
              {
                user: "velotax",
                text: cur.formattedGptAnswer
                  ? DOMPurify.sanitize(cur.formattedGptAnswer)
                  : cur.botResponse,
              } as Msg,
            ],
            [] as Msg[]
          ),
        ]);
      }
    } catch {
    } finally {
      setFirstLoading(false);
      scrollToBottom();
    }
  }, [scrollToBottom, storage, after24H]);

  const sendMsg = async (text: string) => {
    setMsgs((msgs) => [...msgs, { user: "user", text }]);
    setLoading(true);
    scrollToBottom();
    setTimeout(() => setMsg(""), 100);
    try {
      const threadId = storage.getItem(KEY);
      const { data } = await chatApi.post("/chat/user", {
        userMessage: text,
        threadId,
      });
      setLoading(false);
      const now = new Date();
      storage.setItem(KEY, data.threadId);
      storage.setItem(
        KEY_TIMESTAMP,
        String(
          new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
        )
      );
      setMsgs((msgs) => [
        ...msgs,
        {
          user: "velotax",
          text: data.formattedGptAnswer
            ? DOMPurify.sanitize(data.formattedGptAnswer)
            : data.botResponse,
        },
      ]);
    } catch {
      setLoading(false);
      setMsgs((msgs) => [
        ...msgs,
        {
          error: true,
          user: "velotax",
          text: "Não conseguimos responder sua mensagem. Tente novamente mais tarde!",
        },
      ]);
    } finally {
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (signed) {
      loadMsgs();
    }
  }, [signed, loadMsgs]);

  return signed ? (
    <>
      <ChatButton
        className={clsx({ hide })}
        onClick={() => {
          setShow((show) => {
            if (!show && !scrolled) {
              setScrolled(true);
              scrollToBottom();
            }
            return !show;
          });
        }}
      >
        <IoChatbubblesOutline />
      </ChatButton>
      <ChatWindow className={clsx({ hide, hidden: !show })}>
        <div className="chat-header">
          <div className="chat-icon">
            <VelotaxIcon />
          </div>
          <h1>Assistente Velotax</h1>
          <div className="reset-icon" onClick={resetChat}>
            <GrPowerReset size={16} />
          </div>
          <div className="close-icon" onClick={() => setShow(false)}>
            <BsX />
          </div>
        </div>
        <div className="chat-body" ref={chatBodyRef}>
          <div className="chat-body-conversation">
            {firstLoading ? (
              <></>
            ) : (
              msgs.map(({ user, text, error }, i) =>
                user === "velotax" ? (
                  <div className="chat-msg" key={i}>
                    <span className={clsx("chat-msg-icon", { error })}>
                      {error ? <MdErrorOutline /> : <VelotaxIcon />}
                    </span>
                    <span
                      className="chat-mgs-text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </div>
                ) : (
                  <div className="chat-msg chat-msg-user" key={i}>
                    <span className="chat-mgs-text">{text}</span>
                  </div>
                )
              )
            )}
            {(loading || firstLoading) && (
              <div className="chat-msg">
                <div className="chat-msg">
                  <span className="chat-msg-icon">
                    <VelotaxIcon />
                  </span>
                  <span className="chat-mgs-text">
                    <LoadingOutlined />
                  </span>
                </div>
              </div>
            )}
            <div className="chat-body-white-space" />
          </div>
        </div>
        <div className="chat-footer">
          <div className="chat-input-container">
            <textarea
              rows={2}
              value={msg}
              ref={textareaRef}
              placeholder="Digite sua dúvida aqui..."
              onChange={(e) => {
                setMsg(e.target.value || "");
                const textarea = textareaRef.current;
                if (textarea) {
                  textarea.style.height = "auto";
                  textarea.style.height = `${textarea.scrollHeight}px`;
                }
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" && !disabled) {
                  sendMsg(msg);
                }
              }}
            />
            <button
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  sendMsg(msg);
                }
              }}
            >
              <FaPaperPlane size={20} />
            </button>
          </div>
        </div>
      </ChatWindow>
    </>
  ) : (
    <></>
  );
};
