import clsx from "clsx";
import moment from "moment-timezone";
import { BsEye } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLock, AiOutlineWarning } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import {
  Col,
  List,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { Darf } from "../Darf";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import { DarfExterior } from "../DarfExterior";
import { CardXP } from "../../components/CardXP";
import apiExterior from "../../services/apiExterior";
import { useAuth } from "../../contexts/AuthContext";
import { PayButton } from "../HistoricBolsa/PayButton";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { itensExterior } from "../Exterior/itensExterior";
import { DrawerModal } from "../../components/DrawerModal";
import { PaymentModal } from "../../components/PaymentModal";
import { PayDarfButton } from "../HistoricBolsa/PayDarfButton";
import {
  maxCreditCardPayment,
  maxDarfPrice,
  maxPixPayment,
  minDarfPrice,
  yearConfigPL4173,
} from "../../constants/darf";
import {
  download,
  errorMessage,
  formatCurrency,
  getInitialYear,
  getLastBusinessDay,
  isMobile,
  monthsExtended,
} from "../../utils";
import { itensCrypto } from "../Crypto/itensCrypto";
import { instanceMsCalc } from "../../services/msCalc";
import { ModalConfirmMail } from "../../components/ModalConfirmMail";

interface ShowCalcModal {
  visible: boolean;
  view?: boolean;
  month?: number;
  year?: number;
  calc?: "cripto" | "exterior";
}

interface HistoricProps {
  item: Page;
}

export const Historic: React.FC<HistoricProps> = ({ item }) => {
  const { currentBroker } = useBroker();
  const { state, pathname } = useLocation();
  const navigate = useNavigate()
  const {
    user,
    hasPlan: hasPremiunPlan,
    hasPermissionExterior,
    hasPermissionGeneral,
    showUserPlanModal,
  } = useAuth();
  const hasPlan =
    hasPremiunPlan || hasPermissionExterior || hasPermissionGeneral;

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const [holidays, setHolidays] = useState<any[]>([]);
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  window.history.replaceState({}, document.title);

  const [data, setData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [itemToPay, setItemToPay] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [showErrorCard, setShowErrorCard] = useState(false)
  const [errorDates, setErrorDates] = useState<{ month: number, year: number }[]>([]);
  const [year, setYear] = useState(stateYear ?? initialYear);
  const [reportData, setReportData] = useState<any>(null)
  const [month, setMonth] = useState(0);
  const [showCalcModal, setShowCalcModal] = useState<ShowCalcModal>({
    visible: false,
  });
  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  const historicFeature = item.features[0];
  const payFeature = item.features[1];
  const downloadFeature = item.features[2];

  const isCripto = pathname.includes("cripto");
  const isExterior = pathname.includes("exterior");
  const backend = isExterior ? apiExterior : apis;
  const oldModel = year < yearConfigPL4173;

  const yearOptions = Array.from(
    Array(initialYear - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const getDowloadLinks = useCallback(
    (data: any[]) => {
      const dataLinks = data.map((item) =>
        !!item.fullPath && !!item._id
          ? backend.get(`${downloadFeature.apiUrl}/${item._id}`)
          : Promise.resolve({ data: item })
      );
      Promise.allSettled(dataLinks).then((response) => {
        setData(
          response.map((item, index) =>
            item.status === "fulfilled" &&
              typeof item.value.data?.url === "string"
              ? { ...data?.[index], url: item.value.data.url }
              : data?.[index]
          )
        );
      });
    },
    [backend, downloadFeature]
  );

  const getHistoric = useCallback(() => {
    setLoading(true);
    backend
      .get(historicFeature.apiUrl, {
        params: { year },
      })
      .then((response) => {
        const data = (response.data ?? []).reverse();
        setData(data);
        getDowloadLinks(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [historicFeature, year, backend, getDowloadLinks]);

  const payAction = useCallback(
    (payed: boolean, item: any) => {
      setLoading(true);
      backend
        .put(`${payFeature.apiUrl}/${item._id}`, { payed })
        .then(() => {
          getHistoric();
          setData((data) =>
            (data || []).map((darf) => ({
              ...darf,
              payed: item._id === darf._id ? payed : darf.payed,
            }))
          );
        })
        .catch(() => message.error(errorMessage))
        .finally(() => setLoading(false));
    },
    [payFeature, getHistoric, backend]
  );

  const sendDarfToEmail = (darfId: string) => {
    backend
      .get(`/darf/sendEmail/${darfId}`)
      .then(() => {
        oldModel
        ? message.success("O DARF foi enviado para o seu e-mail")
        : message.success("O Relatório foi enviado para o seu e-mail");
      })
      .catch(() => {
        console.log("Algo inesperado aconteceu. Tente novamente!");
      });
  };

  const getErrors = () => {
    apiExterior
      .get(`/transaction/hasError`)
      .then((res: any) => {
        setShowErrorCard(res?.data?.error?.show && !res?.data?.error?.errorDates?.length)
        setErrorDates(res?.data?.error?.errorDates || [])
      })
      .catch(() => {
        console.log("Erro no error");
      });
  }

  const editAction = (
    record: any,
    view: boolean,
    index: number,
    noPlanMode?: boolean
  ) => {
    if (!hasPlan && !noPlanMode) {
      navigate('/planos')
    } else {
      const { month, year } = record;
      setShowCalcModal({
        visible: true,
        view,
        year,
        month,
        calc: isExterior ? "exterior" : "cripto",
      });
      if (view) {
        setItemToPay(record);
      }
    }
  };

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const valueIsGreaterThanMin = (data: any) =>
    data?.impostoDevido + (data?.impostoAcumulado || 0) - (data.irrfExterior || 0) >= minDarfPrice;

  const valueIsLessThanMax = (data: any) =>
    data?.totalImpostoDevido <= maxDarfPrice;

  const showPayButton = (item: any) =>
    (valueIsGreaterThanMin(item) &&
    valueIsLessThanMax(item) &&
    (item.status !== "PAYED" || !item.payed)) || 
    (year >= yearConfigPL4173 
      && item.transactions?.filter((t: any) => ['TRADE', 'DIVIDEND', 'INTEREST'].includes(t.type)).length > 0
    );

  const handleOpenPlanModal = () => {
    navigate('/planos')
  };

  const handlePayButton = (item: any) => {
    if (year >= yearConfigPL4173) {
      handleDarf(false, item);
    } else if (!hasPlan) {
      handleOpenPlanModal();
    } else {
      getTaxes(item.month);
      setPaymentModal(!paymentModal);
    }
  };

  const handleOpenDarfModal = (justSave?: boolean, obj?: any) => {
    handleDarf(justSave, obj);
  };

  const handleCloseCalcModal = () => {
    setShowCalcModal({ visible: false });
    getHistoric();
  };

  const getTaxes = useCallback(
    (month: number, callback?: (obj: any) => void) => {
      if(month === 0) month = 1
      backend
        .get("/transaction", { params: { month, year } })
        .then((response) => {
          const data = {
            ...response.data,
            year,
            month,
            name: user.user.name,
            memoriaCalculo: [],
            transactions: response.data.memoriaCalculo,
          };
          setItemToPay(data);
          callback?.(data);
        })
        .catch(() => { });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [year, user.user.name]
  );

  const sendReportMail = useCallback(() => {
    const reportType = reportData.type || 'darf';
    if (!reportData) {
      message.error("Darf não encontrado!")
      return;
    }
    backend.get(`/darf/report/mail/${reportType}`, {
      params: {
        year: reportData?.year,
        month: reportData?.month,
        filePath: reportData?.filePath,
        ...reportData,
      }
    }).then(() => {
      message.success("Relatório enviado para seu email.")
    })
    .catch(() => {
      message.error("Houve um erro ao enviar seu relatório!")
    })
  }, [backend, reportData])

  const handleDarf = (justSave?: boolean, obj?: any) => {
    setLoading(true);
    setEmitting(true);
    const darfData = obj ? obj : itemToPay;
    backend
      .post("/darf", {
        ...darfData,
        year,
        month: darfData?.month,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: darfData?.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave: false,
        noSendMail: true,
      })
      .then((res) => {
        getHistoric();
        setEmitting(false);
        if (!justSave) {
          setPaymentModal(year >= yearConfigPL4173 ? false : !paymentModal);
          
          oldModel
          ? message.success("O DARF foi emitido com sucesso!")
          : message.success("O Relatório foi emitido com sucesso!");

          const darfId = res.data?.id || darfData._id;
          if (darfId && oldModel) {
            setReportData(darfId);
          }

          if (res.data?.filePath) {
            setReportData({
              year:  year,
              month:  darfData?.month,
              filePath:  res.data?.filePath,
              type: 'darf',
              totalImpostoDevido: darfData?.totalImpostoDevido ?? 0
            })
          }

          download(res.data.darfUrl);
        } else {
          payAction(true, { ...data, _id: res.data?.darf?._id });
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exteriorTransactionsFilter = (el: any) =>
    (el.operation === "SELL" && !el?.type) ||
    (el.operation === "SELL" && el.manual) ||
    (el.operation === "SELL" && el.type === "TRADE");

  const exteriorTransactionsFilterCalc = (el: any) =>
    (el.operation === "SELL" && !el?.type) ||
    (el.operation === "SELL" && el.manual) ||
    (el.operation === "SELL" &&
      (el.type === "TRADE" || el.type === "INTEREST"));

  const disableCurrentMonth = (item: any) =>
    item.year === currentYear && item.month === currentMonth + 1 && item.year < yearConfigPL4173;

  const vendasTotais = (item: any) => {
    return isExterior
      ? item.transactions
        ?.filter(exteriorTransactionsFilter)
        .reduce((a: number, t: any) => a + (oldModel ? ((t.valor || 0) * (t.cambioVenda || 0)) : (t.sellPrice_BRL || 0) * (t.quantidade || 0)), 0)
      : item.transactions.reduce(
        (a: number, t: any) => a + t.quantity * t.sellPrice,
        0
      );
  };

  const handleFinalValue = (item: any) =>
    Number(
      (item.impostoDevido || 0) +
      (oldModel || item.month == 1 ? (item.impostoAcumulado || 0) : 0)
      - (item.irrfExterior || 0 ) +
      ((item.impostoDevido || 0) + (item.impostoAcumulado || 0) - (item.irrfExterior || 0 ) < minDarfPrice || !oldModel
        ? 0
        : (item.juros || 0) + (item.multa || 0))
    );

  const ImpostoAPagarNoPlan = (
    <Typography.Text className="no-plan-imposto-a-pagar">
      <span>Imposto a pagar: </span>
      <Button
        type="link"
        onClick={() => {
          navigate("/planos")
        }}
      >
        <strong>
          Clique para verificar&nbsp;
          <AiOutlineLock size={14} />
        </strong>
      </Button>
    </Typography.Text>
  );

  const ErrorCard = () => {
    return (
      <div className="errorCard">
        <p className="errorTitle">
          Atenção
        </p>
        <p className="errorText">
          Não foi possivel carregar as suas informações.
        </p>
        <p className="errorText">
          Entre em contato através do e-mail <strong> suporte@velotax.com.br </strong>
        </p>
      </div>
    )
  }

  const ImpostoAPagarWithPlan: React.FC<{ item: any }> = ({ item }) => {
    setMonth(item.month);
    const value = handleFinalValue(item);
    return (
      <Typography.Text>
        <span>Imposto a pagar: </span>
        <strong className="tax-value">
          {value > 0 ? formatCurrency(value) : "Isento"}
        </strong>
      </Typography.Text>
    );
  };

  const getHolidays = async () => {
    instanceMsCalc.get(`/rates/get-holidays`)
      .then(({ data }) => {
        const dataFormated = data?.map((el: any) => {
          return moment(el).tz('Europe/London').format('MM-DD');
        });
        setHolidays(dataFormated);
      })
      .catch(() => console.error('Erro ao buscar feriados'));

  }

  useEffect(() => {
    getErrors()
  }, [])

  useEffect(() => {
    getHistoric();
    getHolidays();
  }, [getHistoric, year]);

  useEffect(() => {
    getTaxes(Number(month ?? currentMonth ?? 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <Container className="velotax-container">
      <Typography.Title level={1} className="page-title">
        Impostos devidos (DARF)
      </Typography.Title>
      <Content className="velotax-content historic">
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          placeholder="Selecione o ano"
          style={{ minWidth: "150px" }}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
        {
          showErrorCard &&
          <ErrorCard />
        }
        <List
          loading={loading}
          itemLayout="horizontal"
          className={clsx("velotax-historic-list", { "no-plan": !hasPlan })}
          dataSource={
            data?.filter(
              (item) =>
                !(item.year === currentYear && item.month >= currentMonth + 1)
            ) ?? []
          }
          renderItem={(item, index) => {
            const ultimoDiaUtilDoMes = getLastBusinessDay(
              new Date(item.year, item.month + 1, 0),
              holidays,
            );
            const vencimento = moment(ultimoDiaUtilDoMes).format("DD/MM/YYYY");
            const currentDarfDate = new Date(year, item.month, 15);
            let dateAllowedOnBasic = new Date(today.getFullYear(), today.getMonth() - 1, 1)
            if(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt) {
              dateAllowedOnBasic = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt);
              dateAllowedOnBasic.setMonth(dateAllowedOnBasic.getMonth() - 2)
            }
            const checkMonth = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime());
            const hideOnBasic = isBasic && checkMonth;
            return (
              <List.Item
                actions={
                  !!errorDates.find(ed => ed.month === item.month && ed.year === item.year) ? [] :
                  (!hasPlan || hideOnBasic)
                    ? item.transactions?.length > 0 && !isMobile()
                      ? [
                        <Button
                          type="link"
                          icon={<BsEye size={16} />}
                          onClick={() => editAction(item, true, index, true)}
                        >
                          <strong>Visualizar</strong>
                        </Button>,
                      ]
                      : []
                    : !isMobile()
                      ? [
                        showPayButton(item) && (
                          <PayButton
                            onClick={() => handlePayButton(item)}
                            disabled={disableCurrentMonth(item)}
                            title={
                              disableCurrentMonth(item)
                                ? "Aguarde o encerramento do mês para pagar seu DARF"
                                : ""
                            }
                            isReport={!oldModel}
                          />
                        ),
                        <Button
                          type="link"
                          icon={<BsEye size={16} />}
                          onClick={() =>
                            editAction(
                              item,
                              item.payed && valueIsGreaterThanMin(item),
                              index
                            )
                          }
                        >
                          {showPayButton(item) ? "" : <strong>Visualizar</strong>}
                        </Button>,
                      ]
                      : []
                }
              >
                <Skeleton title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={
                      <>
                        {monthsExtended[item.month - 1]}
                        {!isMobile() ? ` de ${item.year}` : ""}
                        {(!hasPlan || hideOnBasic) &&
                          item.transactions?.length > 0 &&
                          isMobile() &&
                          !errorDates.find(ed => ed.month === item.month && ed.year === item.year) && (
                            <Button
                              type="link"
                              onClick={() => editAction(item, true, index, true)}
                              icon={
                                <BsEye
                                  size={16}
                                  style={{
                                    minWidth: "24px",
                                    minHeight: "24px",
                                    marginLeft: "12px",
                                    strokeWidth: "0.2px",
                                    fill: "var(--ant-primary-color)",
                                  }}
                                />
                              }
                            />
                          )}
                        {(hasPlan && !hideOnBasic) ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {!errorDates.find(ed => ed.month === item.month && ed.year === item.year) &&
                              valueIsGreaterThanMin(item) &&
                              !disableCurrentMonth(item) &&
                              year < yearConfigPL4173 ? (
                              <PayDarfButton
                                checked={item.payed}
                                checkedChildren="Pago"
                                unCheckedChildren="Em aberto"
                                onChange={() => {
                                  if (
                                    item._id &&
                                    Object.keys(item).includes("fullPath")
                                  ) {
                                    payAction(!item.payed, item);
                                  } else {
                                    setLoading(true);
                                    getTaxes(item.month, (obj: any) => {
                                      handleOpenDarfModal(true, obj);
                                    });
                                  }
                                }}
                              />
                            ) : undefined}
                            {isMobile() && !errorDates.find(ed => ed.month === item.month && ed.year === item.year) && (
                              <Button
                                type="link"
                                icon={
                                  <BsEye
                                    size={16}
                                    style={{
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      marginLeft: "12px",
                                      strokeWidth: "0.2px",
                                      fill: "var(--ant-primary-color)",
                                    }}
                                  />
                                }
                                onClick={() =>
                                  editAction(
                                    item,
                                    item.payed && valueIsGreaterThanMin(item),
                                    index
                                  )
                                }
                              />
                            )}
                          </div>
                        ) : undefined}
                      </>
                    }
                    description={
                      !!errorDates.find(ed => ed.month === item.month && ed.year === item.year) ? <div className="monthError">
                        <AiOutlineWarning className="warning"/>
                        Não foi possível carregar as suas operações. <br/>
                        Entre em contato através do e-mail suporte@velotax.com.br.
                      </div> :
                        <Space direction="vertical">
                          {!hasPlan || hideOnBasic ?
                            (
                              <>
                                {(isExterior
                                  ? item.transactions?.filter(
                                    exteriorTransactionsFilterCalc
                                  )
                                  : item.transactions
                                )?.length > 0 ? (
                                  <>
                                    <Typography.Text>
                                      <span>Vendas totais: </span>
                                      <strong>
                                        {formatCurrency(vendasTotais(item))}
                                      </strong>
                                    </Typography.Text>
                                    {ImpostoAPagarNoPlan}
                                  </>
                                ) : (
                                  <Typography.Text>
                                    Não houve movimentações tributáveis neste mês
                                  </Typography.Text>
                                )}
                              </>
                            ) :
                            (
                              <>
                              {((item.transactions?.filter((t: any) => ['TRADE', 'DIVIDEND', 'INTEREST'].includes(t.type)).length > 0)) ? (
                                  <>
                                    <Typography.Text>
                                      <span>Vendas totais: </span>
                                      <strong>
                                        {formatCurrency(vendasTotais(item))}
                                      </strong>
                                    </Typography.Text>
                                    <ImpostoAPagarWithPlan item={item} />
                                    {handleFinalValue(item) > 0 && year < yearConfigPL4173 && (
                                      <Typography.Text>
                                        <span>Vencimento: </span>
                                        <span>
                                            {vencimento}
                                        </span>
                                      </Typography.Text>
                                    )}
                                    {isMobile() && showPayButton(item) && (
                                      <PayButton
                                        fullWidth
                                        onClick={() => handlePayButton(item)}
                                        disabled={disableCurrentMonth(item)}
                                        title={
                                          disableCurrentMonth(item)
                                            ? "Aguarde o encerramento do mês para pagar seu DARF"
                                            : ""
                                        }
                                        isReport={!oldModel}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Typography.Text>
                                    Não houve movimentações tributáveis neste mês
                                  </Typography.Text>
                                )}
                              </>
                            )}
                        </Space>
                    }
                  />
                </Skeleton>
              </List.Item>
            )
          }}
        />

        <Typography.Title level={2} className="subtitle-lined">
          <span>Outras opções</span>
        </Typography.Title>

        <div>
          {(isExterior
            ? [...itensExterior.slice(1)]
            : isCripto
              ? [...itensCrypto.slice(1)]
              : ([] as any[])
          )
            .filter((item: any) => item.id !== "anual-report")
            .map((item: any) => (
              <CardXP
                id={item.id}
                key={item.id}
                icon={item.icon}
                link={item.link}
                className={clsx("xp-list-card", {
                  "no-plan": !hasPlan,
                  "not-free":
                    !hasPlan &&
                    (item.id !== "integration" || item.id !== "insert-manual"),
                })}
                onClick={
                  (hasPlan && !isBasic) ||
                    item.id === "integration" ||
                    item.id === "insert-manual"
                    ? undefined
                    : () => {
                      handleOpenPlanModal();
                    }
                }
              >
                {(hasPlan && !isBasic) ||
                  item.id === "integration" ||
                  item.id === "insert-manual" ? (
                  <>
                    {item.content}
                  </>
                ) : (
                  <>
                    <Row align="middle" justify="space-between" gutter={[8, 8]}>
                      <Col>{item.content}</Col>
                      <Button
                        type="primary"
                        className="btn-premium"
                        icon={<AiOutlineLock size={18} />}
                        onClick={() => {
                          handleOpenPlanModal();
                        }}
                      >
                        PREMIUM
                      </Button>
                    </Row>
                  </>
                )}
              </CardXP>
            ))}
        </div>

        <BackButton
          to={
            isExterior ? "/xpinvestimentos/exterior" : "/xpinvestimentos/cripto"
          }
          className="historic-exterior"
        />
      </Content>

      <PaymentModal
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        maxCCValue={maxCreditCardPayment}
        closeDarfModal={handleCloseCalcModal}
        disablePixOption={
          (handleFinalValue(itemToPay || {}) || 0) > maxPixPayment
        }
        disableCreditCardOption={
          (handleFinalValue(itemToPay || {}) || 0) > maxCreditCardPayment
        }
        closeModal={() => {
          setPaymentModal(!paymentModal);
        }}
        onCancel={() => {
          setPaymentModal(!paymentModal);
        }}
        callDarf={() => {
          handleOpenDarfModal();
          // if (isCripto) {
          //   HandleTag("78");
          // }
          // if (isExterior) {
          //   HandleTag("77");
          // }
        }}
        paymentData={{
          ...itemToPay,
          impostoTotalFinal:
            (itemToPay?.totalImpostoDevido || 0) +
            (itemToPay?.impostoAcumulado || 0),
        }}
      />

      <DrawerModal
        title=""
        width={700}
        className="darf-modal"
        visible={showCalcModal.visible}
        onCancel={handleCloseCalcModal}
        bodyStyle={{
          padding: "0",
        }}
      >
        {isExterior ? (
          <DarfExterior
            view={showCalcModal.view}
            viewEdit={!showCalcModal.view}
            closeModal={handleCloseCalcModal}
            date={{
              year: showCalcModal.year ?? 0,
              month: showCalcModal.month ? showCalcModal.month - 1 : 0,
            }}
            darf={
              (hasPlan || isBasic) && showCalcModal.view
                ? {
                  ...itemToPay,
                  id: itemToPay.id ?? itemToPay._id,
                }
                : undefined
            }
            item={{
              path: "",
              title: "",
              header: true,
              sidebar: true,
              name: "Calculadora",
              component: "DarfExterior",
              settings: {
                showNavigateToIntegrationButton: true,
                historicUrl: "/xpinvestimentos/exterior-historic",
                integrationUrl: "/xpinvestimentos/exterior-integrations",
              },
              features: [
                {
                  apiUrl: "/transaction",
                },
                {
                  apiUrl: "/darf",
                  buttonLabel: "Emitir DARF",
                },
                {
                  apiUrl: "/darf/year",
                },
              ],
            }}
          />
        ) : isCripto ? (
          <Darf
            view={showCalcModal.view}
            viewEdit={!showCalcModal.view}
            closeModal={handleCloseCalcModal}
            date={{
              year: showCalcModal.year ?? 0,
              month: showCalcModal.month ? showCalcModal.month - 1 : 0,
            }}
            darf={
              hasPlan && showCalcModal.view
                ? {
                  ...itemToPay,
                  id: itemToPay.id ?? itemToPay._id,
                }
                : undefined
            }
            item={{
              path: "",
              title: "",
              header: true,
              sidebar: true,
              disabled: true,
              component: "Darf",
              name: "Calculadora",
              settings: {
                showNavigateToIntegrationButton: true,
                historicUrl: "/xpinvestimentos/cripto-historic",
                integrationUrl: "/xpinvestimentos/crypto-integrations",
              },
              features: [
                {
                  apiUrl: "/transaction",
                },
                {
                  apiUrl: "/darf",
                  buttonLabel: "Emitir DARF",
                },
                {
                  apiUrl: "/darf/year",
                },
              ],
            }}
          />
        ) : undefined}
      </DrawerModal>
      <ModalConfirmMail
          closeFn={() => setReportData(null)}
          visible={Boolean(reportData)}
          action={(sendMail) => {
            if (sendMail) {
              if (oldModel) sendDarfToEmail(reportData);
              else sendReportMail()
            }
          }}
        />
    </Container>
  );
};
