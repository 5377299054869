import React from "react";
import { BiErrorAlt } from "react-icons/bi";
import { Col, Row, Typography } from "antd";
import Button from "../../../../../../Button";

interface IErrorScreenProps {
  setCurrentStep: (currentStep: number) => void;
}

export const ErrorScreen: React.FC<IErrorScreenProps> = ({
  setCurrentStep,
}) => {
  const callBack = (cb: Function) => {
    setCurrentStep(0);
    setTimeout(() => cb(), 200);
  };

  return (
    <Row justify="center" style={{ marginTop: "64px" }}>
      <Col>
        <BiErrorAlt size={72} color="var(--ant-error-color)" />
      </Col>

      <Col span={24} style={{ marginTop: "24px" }}>
        <Typography.Paragraph style={{ fontSize: 16, textAlign: "center" }}>
          Ocorreu um erro durante a confirmação do pagamento.
        </Typography.Paragraph>
      </Col>

      <Button
        size="large"
        onClick={() => {
          callBack(() => {});
        }}
      >
        Escolher outra forma de pagamento
      </Button>
    </Row>
  );
};
