import { message } from "antd";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import apiBolsa from "../services/apiBolsa";
import { Integration, useBroker } from "./BrokerContext";
import HandleTag from "../services/handleTag";
import { B3AuthURL, errorMessage, isMobile } from "../utils";
import { ModalConfirmWaitIntegration } from "../components/LoadingIntegration/confirm.modal";
import { ItegrationStatus } from "../components/Sidebar";
import { useWebSocket, WSClientEvents } from "./Notification.context";
import moment from "moment";

interface HandleIntegrationProps {
  integrationInBackground?: boolean;
  onlyPrepareBolsa?: boolean;
}

export interface IntegrationStatus {
  description?: string;
  started?: boolean;
  finished?: boolean;
  error?: boolean;
  alert?: boolean;
  processing?: boolean;
}

interface IB3IntegrationContext {
  timer: number;
  timeOver: boolean;
  openWithLink: boolean;
  b3Authorized: boolean;
  loadingIntegration: boolean;
  loadingAuthorization: boolean;
  checkingAuhtorization: boolean;
  integrationMessage?: string;
  integrationInBackground: boolean;
  setIntegration?: React.Dispatch<React.SetStateAction<Integration>>;
  integrate: (onlyPrepareBolsa?: boolean) => void;
  handleIntegrate: (props?: HandleIntegrationProps) => void;
  resetB3IntegrationContext: () => void;
  setIntegrationLoading: (status: boolean) => void;
  backgroundIntegration: {
    loading: boolean;
    month: number;
    year: number;
  };
  verifyUserIsAuthorized: (
    once?: boolean,
    autoError?: boolean,
    autoSuccess?: boolean
  ) => void;
  interval: React.MutableRefObject<NodeJS.Timeout | undefined>;
  notas: {
    downloadNotaCorretagem: (
      path: string,
      month: number,
      year: number
    ) => void;
    historicoNotasCorretagem: any[];
    getHistoricoNotasCorretagem: (params: {
      crypto?: boolean;
      filters: any;
    }) => void;
    loadingHistoricoNotasCorretagem: boolean;
    showHistoricoNotasCorretagemModal: boolean;
    setHistoricoNotasCorretagem: Dispatch<SetStateAction<any[]>>;
    setShowHistoricoNotasCorretagemModal: Dispatch<SetStateAction<boolean>>;
  };
}

export const B3IntegrationContext = createContext<IB3IntegrationContext>(
  {} as IB3IntegrationContext
);

export const keyIntegrationDate = "@VeloTax:integrationTimer";

export const B3IntegrationProvider: React.FC = ({ children }) => {
  const { user, b3Authorized, setB3Authorized } = useAuth();
  const { integration, initIntegration, handleIntegrationInit, getIntegrationStatus, setInitIntegration } = useBroker();

  const [timer, setTimer] = useState(0);
  const [timeOver, setTimeOver] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const [loadingAuthorization, setLoadingAuthorization] = useState(false);
  const [checkingAuhtorization, setCheckingAuhtorization] = useState(false);
  const [integrationInBackground, setIntegrationInBackground] = useState(false);
  const [historicoNotasCorretagem, setHistoricoNotasCorretagem] = useState<
    any[]
  >([]);
  const [loadingHistoricoNotasCorretagem, setLoadingHistoricoNotasCorretagem] =
    useState(false);
  const [
    showHistoricoNotasCorretagemModal,
    setShowHistoricoNotasCorretagemModal,
  ] = useState(false);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const timerInterval = useRef<ReturnType<typeof setInterval>>();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [integrationMessage, setIntegrationMessage] = useState<string>();
  const integrationLoadingRef = useRef<boolean>(false);

  const openWithLink =
    !timeOver && !checkingAuhtorization && !b3Authorized && isMobile();


  const backgroundIntegration = useMemo(() => {
    const lastUpdateDate = moment(integration.lastUpdate ?? new Date(2000, 0, 1));
    const month = lastUpdateDate.get("month") + 1;
    const year = lastUpdateDate.get("year");
    return { loading: initIntegration, month, year };
  }, [initIntegration, integration?.lastUpdate]);

  useEffect(() => {
    if (checkingAuhtorization) {
      setTimer(90);
      timerInterval.current && clearInterval(timerInterval.current);
      timerInterval.current = setInterval(() => {
        setTimer((timer) => (timer - 1 <= 0 ? 0 : timer - 1));
      }, 1000);
    } else {
      setTimer(0);
      timerInterval.current && clearInterval(timerInterval.current);
    }
    return () => {
      timerInterval.current && clearInterval(timerInterval.current);
    };
  }, [checkingAuhtorization]);

  const downloadNotaCorretagem = async (
    path: string,
    month: number,
    year: number,
  ) => {
    try {
      const { data } = await apiBolsa.get(
        "/brokerage-notes/download",
        {
          params: { path: path.replace("notas/", ""), month, year },
        }
      );
      if (data.url) {
        window.open(data.url);
      }
    } catch (error) {
      message.error("Algo errado aconteceu. Tente novamente mais tarde.");
    }
  };

  const getHistoricoNotasCorretagem = async ({
    filters,
    crypto,
  }: {
    crypto?: boolean;
    filters: any;
  }) => {
    setLoadingHistoricoNotasCorretagem(true);
    const filterDay = filters?.day || null;
    const filterMonth = filters?.month || null;
    const filterYear = filters?.year || null;
    try {
      setHistoricoNotasCorretagem([]);
      const { data } = await apiBolsa.get(
        `/brokerage-notes/history`,
        {
          params: {
            day: filterDay,
            month: filterMonth,
            year: filterYear,
          },
        }
      );
      if (data?.message) {
        message.error(data.message);
      } else {
        setHistoricoNotasCorretagem(
          data.map((nota: any) => ({
            ...nota,
            id: `${nota.month}${nota.year}`,
          }))
        );
      }
    } catch (error: any) {
      message.error("Algo errado aconteceu. Tente novamente mais tarde.");
    } finally {
      setLoadingHistoricoNotasCorretagem(false);
    }
  };

  const openB3Window = useCallback(() => {
    window.open(
      B3AuthURL,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  }, []);

  const setIntegrationLoading = useCallback((status: boolean) => {
    if (status) {
      if (integrationLoadingRef.current) return;
      // console.log("INICIOU INTEGRAÇÃO", new Date())
      integrationLoadingRef.current = true;
      setInitIntegration(true)
      setLoadingIntegration(true)
    } else {
      // console.log("FINALIZOU INTEGRAÇÃO", new Date())
      integrationLoadingRef.current = false;
      setLoadingIntegration(false);
      setInitIntegration(false)
    }
  }, [])

  const integrate = useCallback(
    (onlyPrepareBolsa?: boolean) => {
      HandleTag("50");
      setIntegrationLoading(true);
      apiBolsa
        .post("/xpinvestimentos/integrate", {
          email: user.user.email,
          onlyPrepareBolsa,
        })
        .catch((err) => {
          if (err?.response?.data?.message?.includes("Aguarde")) {
            message.error(err?.response?.data?.message);
          } else {
            message.error(errorMessage);
          }
        })
        .finally(() => {
          setIntegrationLoading(false);
        });
    },
    [setIntegrationLoading, user?.user?.email]
  );

  const verifyUserIsAuthorized = useCallback(
    (once?: boolean, autoError?: boolean, autoSuccess?: boolean) => {
      let count = 0;

      const requestUserAuthorization = (
        interval?: NodeJS.Timeout,
        firstTime?: boolean
      ) =>
        apiBolsa
          .get("/xpinvestimentos/verify-user")
          .then((response) => {
            count += 1;
            if (response?.data?.isAuthorized) {
              setB3Authorized(true);
              setCheckingAuhtorization(false);
              (interval || firstTime) &&
                message.success("Permissão concedida com sucesso!", 5);
              (interval || firstTime || autoSuccess) && integrate();
              interval && clearInterval(interval);
            } else if (autoError) {
              resetB3IntegrationContext();
              // openB3Window();
              // setCheckingAuhtorization(true);
              // verifyUserIsAuthorized();
            }
            if (interval && count > 12) {
              setTimeOver(true);
              clearInterval(interval);
              setCheckingAuhtorization(false);
            }
            return response;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              message.error(err?.response?.data?.message);
            }
            if (interval) {
              setTimeOver(true);
              clearInterval(interval);
              setCheckingAuhtorization(false);
            }
          });

      if (once && autoError && autoSuccess) {
        setLoadingAuthorization(true);
      }
      requestUserAuthorization(undefined, !once)
        .then((response) => {
          if (!once && !response?.data?.isAuthorized) {
            if (interval.current) {
              clearInterval(interval.current);
            }
            interval.current = setInterval(() => {
              requestUserAuthorization(interval.current);
            }, 6000);
          }
        })
        .finally(() => {
          setLoadingAuthorization(false);
        });
    },
    [integrate, setB3Authorized]
  );

  const handleIntegrate = useCallback(
    (props?: HandleIntegrationProps) => {
      if (!checkingAuhtorization) {
        if (timeOver && !b3Authorized) {
          verifyUserIsAuthorized(true, true, true);
        } else if (b3Authorized) {
          setIntegrationInBackground(!!props?.integrationInBackground);
          integrate();
        } else {
          !openWithLink && openB3Window();
          setCheckingAuhtorization(true);
          verifyUserIsAuthorized();
        }
      } else {
        openB3Window();
      }
    },
    [
      timeOver,
      b3Authorized,
      integrate,
      verifyUserIsAuthorized,
      openWithLink,
      openB3Window,
      checkingAuhtorization,
    ]
  );

  const resetB3IntegrationContext = () => {
    setTimeOver(false);
    setLoadingIntegration(false);
    setLoadingAuthorization(false);
    setCheckingAuhtorization(false);
    interval.current && clearInterval(interval.current);
  };

  useEffect(() => {
    if (initIntegration && (integration.hasError || integration.integrated)) {
      resetB3IntegrationContext();
      verifyUserIsAuthorized(true, integration.hasError);
    }
  }, [
    initIntegration,
    integration.hasError,
    integration.integrated,
    verifyUserIsAuthorized,
  ]);

  useEffect(() => {
    !!user.token && !b3Authorized && verifyUserIsAuthorized(true);
  }, [user.token, b3Authorized, verifyUserIsAuthorized]);

  useEffect(() => {
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, []);

  const { addEventListener, removeEventListener, open } = useWebSocket();

  useEffect(() => {
    const evListenerFn = (ev: IntegrationStatus | null) => {
      if (ev?.processing || ev?.started) {
        setIntegrationLoading(true);
        setIntegrationMessage(ev.description);
        return;
      }
      if (ev?.error) {
        setIntegrationMessage(ev.description);
        message.error(
          "Houve um erro durante sua integração. Tente novamente mais tarde."
        );
        setIntegrationLoading(false);
        setInitIntegration(false);
        setModalConfirm(false);
        getIntegrationStatus({
          token: user.token,
          hideLoading: true,
        });
        return;
      }
      if (ev?.finished) {
        setIntegrationMessage(ev.description);
        setInitIntegration(false);
        setModalConfirm(false);
        setIntegrationLoading(false);
        getIntegrationStatus({
          token: user.token,
          hideLoading: true,
        });
        return;
      }
    };
    if (open) {
      addEventListener<IntegrationStatus | null>(
        WSClientEvents.INTEGRATION_STATUS,
        evListenerFn
      );
    }
    return () => {
      removeEventListener(WSClientEvents.INTEGRATION_STATUS, evListenerFn);
    };
  }, [open, user.token]);

  useEffect(() => {
    if (initIntegration) {
      const tm = setTimeout(() => {
        if (integrationLoadingRef.current) {
          setModalConfirm(true);
        }
      }, 50000)
      return () => {
        if (tm) clearTimeout(tm)
      }
    }
  }, [initIntegration])

  return (
    <B3IntegrationContext.Provider
      value={{
        backgroundIntegration,
        timer,
        openWithLink,
        loadingIntegration,
        loadingAuthorization,
        setIntegrationLoading,
        interval,
        timeOver,
        integrate,
        b3Authorized,
        handleIntegrate,
        checkingAuhtorization,
        integrationMessage,
        verifyUserIsAuthorized,
        integrationInBackground,
        resetB3IntegrationContext,
        notas: {
          downloadNotaCorretagem,
          historicoNotasCorretagem,
          setHistoricoNotasCorretagem,
          getHistoricoNotasCorretagem,
          loadingHistoricoNotasCorretagem,
          showHistoricoNotasCorretagemModal,
          setShowHistoricoNotasCorretagemModal,
        },
      }}
    >
      <ModalConfirmWaitIntegration
        action={(res) => {
          apiBolsa.post("/b3/send-integration-mail", { send: res });
          setModalConfirm(false);
        }}
        closeFn={() => setModalConfirm(false)}
        visible={modalConfirm}
      />
      {children}
      {/* <LoadingIntegration
        show={initIntegration}
        hasError={integration.hasError}
      /> */}
    </B3IntegrationContext.Provider>
  );
};

export const useB3Integration = () => useContext(B3IntegrationContext);
