import styled from "styled-components";

export const B3Link = styled.a`
  margin: auto;
  font-size: 10px;
  text-align: center;
  white-space: normal;
  display: inline-block;
  word-break: break-all;
  color: rgb(1, 103, 153);

  &:hover {
    text-decoration: underline;
    color: rgb(1, 103, 153);
  }
`;

export const CopyLink = styled.button`
  color: #0566cd;
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: #06417f;
  }
`;

export const ShareItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #06417f;
  }

  img {
    width: 40px;
    height: 40px;
  }

  p {
    font-size: 11px;
    margin: 0.5rem 0;
  }
`;

export const Instructions = styled.p`
  font-size: 13px;
  margin-bottom: 4px;
`;
