import clsx from "clsx";
import { months } from "../../../../utils";
import {
  YearResumeStatusCSSClassEnum,
  YearResumeStatusFromBackEnum,
} from "../../../../constants/darf";

export interface YearResume {
  year: number;
  months: {
    month: number;
    payed: boolean;
    status: keyof typeof YearResumeStatusFromBackEnum;
  }[];
}

interface DatePickerMonthCellProps {
  event: moment.Moment;
  yearResume: YearResume[];
}

export const DatePickerMonthCell: React.FC<DatePickerMonthCellProps> = ({
  event,
  yearResume,
}) => {
  const date = new Date();
  const year = event.year();
  const month = event.month();
  const item = yearResume
    .find((list) => list.year === year)
    ?.months.find((item) => item.month - 1 === month);
  const disabled = year === date.getFullYear() && month >= date.getMonth();
  return (
    <div
      className={clsx(
        "ant-picker-cell-inner",
        item && !disabled ? YearResumeStatusCSSClassEnum[item.status] : ""
      )}
    >
      {months[month]}
    </div>
  );
};
