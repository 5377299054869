import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ant-info-color);
  height: 100%;

  .sso-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-width: 230px;
      margin-bottom: 40px;
    }

    .sso-area {
      background: var(--velotax-background-color);
      border-radius: 8px;
      padding: 20px;
      width: 500px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h4 {
        margin-bottom: 20px;
      }

      .anticon {
        width: 40px;
        height: 40px;
        color: var(--ant-info-color);

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
