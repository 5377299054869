import { monthsExtended } from "../../utils";

export const monthList = Array.from(Array(12))
  .map((_, i) => ({
    month: i + 1,
    monthName: monthsExtended[i],
    value: 0,
    // impostoPagoExterior: 0,
  }))
  .reverse();
