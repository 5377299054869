import React, { useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { Col, Row, Typography } from "antd";
import { Plan } from "../../../../../../../constants/plans";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { useBroker } from "../../../../../../../contexts/BrokerContext";

interface ConfirmedScreenProps {
  plan?: Plan;
}

export const ConfirmedScreen: React.FC<ConfirmedScreenProps> = ({ plan }) => {
  const {
    user: { token },
  } = useAuth();
  const { getIntegrationStatus } = useBroker();

  useEffect(() => {
    getIntegrationStatus({ token, hideLoading: true });
  }, [getIntegrationStatus, token]);

  return (
    <Row justify="center">
      <Col>
        <MdCheck color={"var(--ant-success-color)"} size={120} />
      </Col>
      <Col>
        <Typography.Paragraph
          strong
          style={{
            fontWeight: 500,
            fontSize: "16px",
            textAlign: "center",
            margin: "16px 0 32px",
          }}
        >
          {plan
            ? `Pagamento confirmado com sucesso! Parabéns você contratou o plano '
            ${plan?.title.toUpperCase()}'`
            : "Pagamento realizado com sucesso!"}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};
