import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import apiBolsa from "../services/apiBolsa";
import apiVeloPro from "../services/apiVeloPro";

export interface Posicao {
  avgPriceUsd: number;
  categoria: string;
  codigo: string;
  posicao: number;
  quantidade: number;
  precoAtual: number;
  precoVenda: number;
  loan?: {
    isLoan?: boolean;
    typeLoan?: string;
  };
  custoMedio: number;
  lucroPrejuizoTotal: number;
  lucroPrejuizoDiario: number;
  lucroPrejuizoTotalPercentual: number;
  lucroPrejuizoDiarioPercentual: number;
  hasPosition2019: boolean;
  corretora: string;
  resumo: any;
}

interface Carteira {
  posicoes: Posicao[];
  posicoesSemFiltro: Posicao[];
  patrimonioTotal: number;
  lucroPrejuizoTotal: number;
  lucroPrejuizoDiario: number;
  lucroPrejuizoTotalPercentual: number;
  lucroPrejuizoDiarioPercentual: number;
  filter?: string[];
  cotacaoVenda: number;
}

interface ICarteiraContext {
  carteira: Carteira;
  loading: boolean;
  lastUpdate: Date;
  getCarteira: (refresh?: boolean) => void;
  getPatrimonios: (cpf: string) => Promise<any>;
  updateFilter: (posicoes: string[]) => Promise<void>;
  posicoesFilter: any;
  setPosicoesFilter: Dispatch<SetStateAction<any>>;
}

export const CarteiraContext = createContext<ICarteiraContext>(
  {} as ICarteiraContext
);

export const CarteiraProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const gettingWalletRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [carteira, setCarteira] = useState<Carteira | any>({});
  const [posicoesFilter, setPosicoesFilter] = useState(carteira.filter);

  const getCarteira = useCallback(
    (refresh?: boolean) => {
      if (gettingWalletRef.current) {
        if (refresh) message.info("Aguarde 10 segundos até solicitar uma nova integração...")
        return;
      }
      gettingWalletRef.current = true;
      setLoading(true);
      apiBolsa
        .get("/xpinvestimentos/wallet", {
          params: {
            refresh,
          },
        })
        .then(({ data }) => {
          setCarteira(data);
          setPosicoesFilter(data?.filter)
          setLastUpdate(new Date());
        })
        .catch((error) => {
          if (error.response?.data?.status === 400) {
            message.info("Refaça sua integração com a B3 e tente novamente.");
            setTimeout(() => {
              navigate("xpinvestimentos/bolsa-integration");
            }, 1000);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      setTimeout(() => {
        gettingWalletRef.current = false;
      }, 10000)
    },
    [navigate]
  );

  const updateFilter = async (posicoes: string[]) => {
    apiBolsa.patch('/xpinvestimentos/wallet/filter', {posicoes})
    .then(() => {
      getCarteira(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getPatrimonios = async (cpf: string) => {
    const { data } = await apiVeloPro.get(`/clients/get-patrimonios/${cpf}`);
    return data;
  };

  return (
    <CarteiraContext.Provider
      value={{
        loading,
        carteira,
        lastUpdate,
        getCarteira,
        getPatrimonios,
        updateFilter,
        posicoesFilter,
        setPosicoesFilter
      }}
    >
      {children}
    </CarteiraContext.Provider>
  );
};

export const useCarteira = () => useContext(CarteiraContext);
