import moment from "moment";
import { message } from "antd";
import { Route, useLocation, useNavigate } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SSO from "../pages/SSO";
import apis from "../services/apis";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { useAuth } from "./AuthContext";
import Cadastro from "../pages/Cadastro";
import { Sucesso } from "../pages/Sucesso";
import apiBolsa from "../services/apiBolsa";
import { Wallet } from "../pages/Carteira";
import { useCarteira } from "./CarteiraContext";
import { MinhaConta } from "../pages/MinhaConta";
import { Regulation } from "../pages/Regulation";
import { tenteIntegrarNovamente } from "../utils";
import { Pages } from "../constants/brokers/pages";
import { Planos } from "../pages/Planos";
import { apiPayment } from "../services/apiPayment";
import { ManagerAccess } from "../pages/ManagerAccess";
import { PGBLSimulator } from "../pages/PGBLSimulator";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ChangePassword } from "../pages/ChangePassword";
import rendimentosBackend from "../services/rendimentos";
import { DeclaracaoIRPF } from "../pages/Declaracao-IRPF";
import { Broker, brokers, Page } from "../constants/brokers";
import { LoadingSession } from "../components/LoadingSession";
import { NameNotification } from "../components/NameNotification";
import { PhoneNotification } from "../components/PhoneNotification";

const UPDATE_INTEGRATION_TIME_STAMP = 1711630800000;

export interface Integration {
  key: string;
  integrated: boolean;
  lastUpdate: string;
  providerExists: boolean;
  plans?: { name: string; status: string };
  secret?: string;
  userId?: string;
  status?: string;
  provider?: string;
  statusTimestamp?: string;
  hasError?: boolean;
}

const defaultIntegration: Integration = {
  key: "unset",
  lastUpdate: "",
  integrated: false,
  providerExists: false,
  hasError: false,
};

interface IBrokerContext {
  currentBroker: Broker;
  currentPage: Page;
  integration: Integration;
  xtageIntegration: boolean;
  integrations: Integration[];
  sidebarPages: Page[];
  integrouHoje: boolean;
  initIntegration: boolean;
  loadingIntegrationStatus: boolean;
  handleIntegrationInit: () => void;
  setInitIntegration: (v: boolean) => void;
  getIntegrationStatus: (options: {
    token?: string;
    firstTime?: boolean;
    hideLoading?: boolean;
    onFinish?: () => void;
  }) => void;
  handleProviderExists: (value: boolean) => void;
  pathBackup: string;
  allPaths: string[];
  brokerRoutes: JSX.Element[];
  publicRoutes: { path: string; element: JSX.Element }[];
  privateRoutes: {
    path: string;
    element: JSX.Element;
    header?: boolean;
    sidebar?: boolean;
  }[];
}

export const BrokerContext = createContext<any>({});

export const BrokerProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hostname } = window.location;
  const { getCarteira } = useCarteira();
  const {
    user,
    loadingSession,
    cameFromRegister,
    hasPlan,
    b3Authorized,
    hasPermissionExterior,
    hasPermissionExteriorPlanos,
    hasPermissionGeneral,
  } = useAuth();

  const [pathBackup, setPathBackup] = useState<string>();
  const [initIntegration, setInitIntegration] = useState(false);
  const [integration, setIntegration] = useState(defaultIntegration);
  const [xtageIntegration, setXtageIntegration] = useState(false);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [loadingIntegrationStatus, setLoadingIntegrationStatus] =
    useState(false);

  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setPathBackup((path) =>
      path !== pathname && pathname !== "/" && !pathname.includes("/p/")
        ? pathname
        : path
    );
  }, [pathname]);

  const brokerList = useMemo(
    () => brokers.filter((broker) => !broker.disabled),
    []
  );

  const dnsBroker = useMemo(
    () => brokerList.find((broker) => hostname.includes(broker.dns)),
    [brokerList, hostname]
  );

  const currentBroker = useMemo(
    () =>
      dnsBroker ||
      brokerList.find((broker) => pathname.includes(broker.path)) ||
      brokerList[0],
    [pathname, brokerList, dnsBroker]
  );

  const integrouHoje = useMemo(
    () =>
      integration.lastUpdate
        ? moment(integration.lastUpdate).isAfter(moment().startOf("day"))
        : false,
    [integration]
  );

  const sidebarPages = useMemo(
    () => currentBroker.pages.filter((page) => page.showOnSidebar),
    [currentBroker?.pages]
  );

  const currentPage = useMemo(() => {
    const current = currentBroker?.pages.find((page) =>
      pathname.includes(page.path)
    );
    if (current?.api) {
      current.api.defaults.headers.common["Authorization"] = `Bearer ${
        user?.token || localStorage.getItem("@VeloTax:token")
      }`;
    }
    return current;
  }, [pathname, user?.token, currentBroker?.pages]);

  const handleIntegrateStatus = useCallback(async ({
    apiName = "bolsa",
    hideLoading,
    firstTime,
    onFinish,
  }: {
    apiName?: "bolsa" | "crypto";
    firstTime?: boolean;
    hideLoading?: boolean;
    onFinish?: () => void;
  }) => {
    !hideLoading && setLoadingIntegrationStatus(true);
    await (apiName === "crypto" ? apis : apiBolsa)
    .get(`providers/integration-status`)
    .then((response) => {
      if (response.data) {
        const pathname = window.location.pathname;
        if (Array.isArray(response.data)) {
          setIntegrations(response.data);
        } else {
          const {
            key,
            integrated,
            lastUpdate,
            providerExists,
            plans,
            hasError,
          } = response.data;
          setIntegration({
            key,
            integrated,
            lastUpdate,
            providerExists,
            plans,
            hasError,
          });
          if (firstTime) {
            if (providerExists) {
              navigate(currentBroker.defaultProviderExistsPath!);
            } else {
              navigate(currentBroker.defaultPath!);
            }
          }
        }
      } else {
        setIntegrations([]);
        setIntegration(defaultIntegration);
      }
    })
    .catch(() => {
      setIntegration(defaultIntegration);
    })
    .finally(() => {
      !hideLoading && setLoadingIntegrationStatus(false)
      onFinish?.()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBroker, user.token])

  const getIntegrationStatus = useCallback(
    ({
      token = user.token,
      hideLoading,
      firstTime,
      onFinish,
    }: {
      token?: string;
      firstTime?: boolean;
      hideLoading?: boolean;
      onFinish?: () => void
    }) => {
      if (token && currentBroker) {
        const pathnameWindowArray = String(window.location.href || "").split(
          "/"
        );
        const pathnameWindow =
          pathnameWindowArray?.[pathnameWindowArray.length - 1] || "";

        // !hideLoading && setLoadingIntegrationStatus(true);
        handleIntegrateStatus({ 
          apiName: pathnameWindow === "crypto-integrations" ? "crypto" : "bolsa",
          firstTime,
          hideLoading,
          onFinish,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBroker, user.token]
  );

  const handleIntegrationInit = useCallback(() => {
    setIntegration((integration) => ({
      ...integration,
      hasError: false,
      integrated: false,
    }));
    setTimeout(() => {
      setInitIntegration(true);
    }, 250);
  }, []);

  const handleProviderExists = useCallback((providerExists: boolean) => {
    setIntegration((integration) => ({ ...integration, providerExists }));
  }, []);

  const abledPages = useMemo(
    () =>
      currentBroker.pages
        .reduce(
          (acc, cur) => [...acc, cur, ...(cur.subPages ?? [])],
          [] as Page[]
        )
        .filter((page) => !!page.component && !page.disabled)
        .filter((page) =>
          page.path.includes("exterior")
            ? hasPlan ||
              hasPermissionExterior ||
              hasPermissionExteriorPlanos ||
              hasPermissionGeneral ||
              !page.premium
            : hasPlan || !page.premium || hasPermissionGeneral
        )
        .filter((page) =>
          hasPermissionGeneral
            ? true
            : !page.path.includes("bolsa")
            ? true
            : b3Authorized && page.integratedOnly
            ? true
            : !b3Authorized && !page.integratedOnly
        ),
    [
      hasPlan,
      b3Authorized,
      currentBroker.pages,
      hasPermissionExterior,
      hasPermissionExteriorPlanos,
      hasPermissionGeneral,
    ]
  );

  const publicRoutes = [
    { path: "/p/login", element: <Login /> },
    { path: "/p/cadastrar", element: <Cadastro /> },
    { path: "/p/forgot-password", element: <ForgotPassword /> },
    { path: "/p/change-password/:token", element: <ChangePassword /> },
    ...(currentBroker.useSSO
      ? [
          {
            path: "/p/sso/:token",
            element: <SSO />,
          },
        ]
      : []),
  ];

  const privateRoutes = [
    { path: "/", element: <Home />, header: true, sidebar: true },
    { path: "/conta", element: <MinhaConta />, header: true, sidebar: true },
    {
      path: "/regulacao",
      element: <Regulation />,
      header: true,
      sidebar: true,
    },
    // {
    //   path: "/declaracao-irpf",
    //   element: <DeclaracaoIRPF />,
    //   header: true,
    //   sidebar: true,
    // },
    ...(!hasPlan || !hasPlan.concierge
      ? [
          {
            path: "/planos",
            element: <Planos />,
            header: true,
            sidebar: true,
          },
        ]
      : []),
    { path: "/gestaoacesso", element: <ManagerAccess /> },
    { path: "/sucesso", element: <Sucesso />, header: false, sidebar: true },
    ...(currentBroker.usePGBL
      ? [{ path: "/pgbl", element: <PGBLSimulator /> }]
      : []),
    ...(currentBroker.useCarteira
      ? [
          {
            path: "/carteira",
            element: <Wallet />,
            header: true,
            sidebar: true,
          },
        ]
      : []),
  ];

  const brokerRoutes = useMemo(
    () =>
      abledPages.map((page) => {
        const Element = Pages[page.component!];
        return (
          <Route
            key={page.path}
            path={page.path}
            element={<Element item={page} />}
          />
        );
      }),
    [abledPages]
  );

  const privatePaths = privateRoutes.map((page) => page.path);
  const publicPaths = publicRoutes.map((page) =>
    page.path.replace("/:token", "")
  );

  const abledPagesPaths = abledPages.map((page) => page.path);
  const allPaths = [...abledPagesPaths, ...privatePaths, ...publicPaths];

  useEffect(() => {
    if (currentBroker?.html.favicon) {
      const link = (document.querySelector("link[rel~='icon']") ||
        document.createElement("link")) as HTMLLinkElement;
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
      link.href = currentBroker?.html.favicon;
    }
    if (currentBroker?.html.title) {
      const title = (document.querySelector("head title") ||
        document.createElement("title")) as HTMLTitleElement;
      document.getElementsByTagName("head")[0].appendChild(title);
      title.innerHTML = currentBroker.html.title;
    }
  }, [currentBroker]);

  useEffect(() => {
    if (integration.integrated && interval.current) {
      clearInterval(interval.current);
      setInitIntegration(false);
      setTimeout(() => {
        if (
          initIntegration &&
          pathname === `/${currentBroker.path}/bolsa-integration`
        ) {
          navigate(`/${currentBroker.path}/bolsa-insert-manual`);
        }
      }, 2500);
    } else if (initIntegration && integration.hasError && interval.current) {
      clearInterval(interval.current);
      setInitIntegration(false);
      setTimeout(() => {
        message.info(tenteIntegrarNovamente, 6);
      }, 300);
    }
  }, [
    integration.integrated,
    integration.hasError,
    pathname,
    currentBroker,
    navigate,
    initIntegration,
  ]);

  useEffect(() => {
    getIntegrationStatus({
      token: user.token,
      hideLoading: true,
    })
  }, [getIntegrationStatus, user.token, integration.key]);

  apis.defaults.headers.common["x-api-provider"] = currentBroker?.id || "";
  apiPayment.defaults.headers.common["x-api-provider"] =
    currentBroker?.id || "";
  rendimentosBackend.defaults.headers.common["x-api-provider"] =
    "velotax-rendimentos" || "";
  return (
    <BrokerContext.Provider
      value={{
        allPaths,
        pathBackup,
        currentPage,
        integration,
        xtageIntegration,
        brokerRoutes,
        integrations,
        integrouHoje,
        sidebarPages,
        publicRoutes,
        currentBroker,
        privateRoutes,
        initIntegration,
        setInitIntegration,
        handleProviderExists,
        getIntegrationStatus,
        handleIntegrationInit,
        loadingIntegrationStatus,
      }}
    >
      {children}
      {!initIntegration && (loadingSession || loadingIntegrationStatus) && (
        <LoadingSession />
      )}
      {/* <PhoneNotification /> */}
      <NameNotification />
    </BrokerContext.Provider>
  );
};

export const useBroker = (): IBrokerContext => useContext(BrokerContext);
