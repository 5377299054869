import { getLastBusinessDay } from "../utils";

export const getDolarQuote = (date: Date, quotes: any[]) => {
  const newDate15Day = new Date(new Date(date).setDate(15));
  let newDateLastMonth = new Date(
    newDate15Day.setMonth(newDate15Day.getMonth() - 1),
  );
  let isRuning = true;
  let count = 0;

  while (isRuning) {
    const lastBusinessDay = getLastBusinessDay(newDateLastMonth);
    const day =
      lastBusinessDay.getDate() <= 9
        ? `0${lastBusinessDay.getDate()}`
        : lastBusinessDay.getDate();
    const month =
      lastBusinessDay.getMonth() + 1 <= 9
        ? `0${lastBusinessDay.getMonth() + 1}`
        : lastBusinessDay.getMonth() + 1;
    const year = lastBusinessDay.getFullYear();

    const cotacaoCompra = quotes.find(q => `${day}/${month}/${year}` === q.dataHoraCotacao)?.cotacaoCompra
    if (cotacaoCompra || count === 5) {
      isRuning = false;
      return cotacaoCompra || 0;
    } else {
      newDateLastMonth = new Date(
        newDateLastMonth.setDate(newDateLastMonth.getDate() - 1),
      );
    }
    count += 1;

  }
};

export const getQuoteFortnight = (date: Date, quotes: any) => {
  const newDate15Day = new Date(new Date(date).setDate(15));
  let newDateLastMonth = new Date(
    newDate15Day.setMonth(newDate15Day.getMonth() - 1),
  );
  let isRuning = true;
  let count = 0;

  while (isRuning) {
    const lastBusinessDay = getLastBusinessDay(newDateLastMonth);
    const day =
      lastBusinessDay.getDate() <= 9
        ? `0${lastBusinessDay.getDate()}`
        : lastBusinessDay.getDate();
    const month =
      lastBusinessDay.getMonth() + 1 <= 9
        ? `0${lastBusinessDay.getMonth() + 1}`
        : lastBusinessDay.getMonth() + 1;
    const year = lastBusinessDay.getFullYear();

    let cotacaoCompra;
    for (const key in quotes) {
      if (`${day}/${month}/${year}` === key) {
        cotacaoCompra = quotes[key]?.compra;
      }
    }
    if (cotacaoCompra || count === 5) {
      isRuning = false;
      return cotacaoCompra || 0;
    } else {
      newDateLastMonth = new Date(
        newDateLastMonth.setDate(newDateLastMonth.getDate() - 1),
      );
    }
    count += 1;

  }
};
