import React from "react";
import { Result, Row } from "antd";
import { ErrorBoundary } from "@rollbar/react";
import Button from "../Button";
import { Container } from "../../pages/PageNotFound";
import { ReactComponent as Veloicon } from "../../assets/velotax/veloicon.svg";

const FallbackUI = () => (
  <Container>
    <Result
      icon={<Veloicon className="error" />}
      title="Erro inesperado"
      subTitle="Um erro inesperado ocorreu"
      extra={
        <Row justify="center">
          <Button
            size="large"
            type="primary"
            onClick={() => (window.location.href = "/")}
          >
            Volte para o app
          </Button>
        </Row>
      }
    />
  </Container>
);

const ErrorBoundaryRollbar: React.FC = ({ children }) => (
  <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>
);

export default ErrorBoundaryRollbar;
