import styled from "styled-components";

export const ExteriorModalContainer = styled.div`
  font-size: 1rem;
  color: var(--velotax-font-color-dark);

  strong {
    font-weight: 600;
  }

  .exterior-capital-gain {
    padding: 8px;
    margin: 24px auto;
    text-align: center;
    background-color: #eee;
  }
`;
