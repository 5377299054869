import { Col, Row } from "antd";
import { taxes } from "../constants/taxes";
import { formatCurrency, numberToPercentage } from "../utils";

export default function getInstallments(
  value: number, // valor
  isPlan: boolean, // caso seja plano irá alterar a descrição
  fixedInstallments?: number //número de parcela fixo
) {
  var _installments = [];
  var customInstallments = 12;

  if (value) {
    if (isPlan) {
      _installments.push({
        value: 12,
        label: (
          <span className="installments-select">
            Mensalidade - R$ {(value / 12).toFixed(2)}
          </span>
        ),
      });
    } else if (fixedInstallments) {
      _installments.push({
        value: 12,
        label: (
          <span className="installments-select">
            Mensalidade - {fixedInstallments}x R${" "}
            {(value / fixedInstallments).toFixed(2)}
          </span>
        ),
      });
    } else {
      for (let i = 0; i < (customInstallments || 12); i++) {
        let _value = formatCurrency((value * taxes[i]) / (i + 1));
        let plural = i > 0 ? "parcelas" : "parcela";

        _installments.push({
          value: i + 1,
          label: (
            <Row
              align="middle"
              justify="space-between"
              className="installments-select"
              gutter={[8, 0]}
            >
              <Col xs={24} sm={14} style={{ textAlign: "start" }}>
                {i + 1}x {plural} - {_value.props.prefix} {_value.props.value}
              </Col>
              <Col xs={24} sm={10} className="installment-tax">
                Taxa de serviço: {numberToPercentage((taxes[i] - 1) * 100)}
              </Col>
            </Row>
          ),
        });
      }
    }
  }

  return _installments;
}
