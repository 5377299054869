export const cpfInputMask = "999.999.999-99";
export const cnpjInputMask = "99.999.999/9999-99";

export const maskCpf = (cpf = "") => {
  if (!cpf) return "";
  if (cpf.includes(".")) return cpf;
  const cpfArray = cpf.split("");
  cpfArray.splice(3, 0, ".");
  cpfArray.splice(7, 0, ".");
  cpfArray.splice(11, 0, "-");
  return cpfArray.join("");
};

export const maskNitPisPasep = (nitpispasep = "") => {
  if (!nitpispasep) return "";
  if (nitpispasep.includes(".")) return nitpispasep;
  const array = nitpispasep.split("");
  array.splice(3, 0, ".");
  array.splice(9, 0, ".");
  array.splice(12, 0, "-");
  return array.join("");
};

export const maskCnpj = (cnpj = "") => {
  if (!cnpj) return "";
  if (cnpj.includes(".")) return cnpj;
  const cnpjArray = cnpj.split("");
  cnpjArray.splice(2, 0, ".");
  cnpjArray.splice(6, 0, ".");
  cnpjArray.splice(10, 0, "/");
  cnpjArray.splice(15, 0, "-");
  return cnpjArray.join("");
};

export const maskCep = (cep = "") => {
  if (!cep) return "";
  if (cep.includes("-")) return cep;
  const cepArray = cep.split("");
  cepArray.splice(5, 0, "-");
  return cepArray.join("");
};

export const maskVoterCard = (voterCard = "") => {
  if (!voterCard) return "";
  const voterCardArray = voterCard.split("");
  voterCardArray.splice(4, 0, " ");
  voterCardArray.splice(9, 0, " ");
  return voterCardArray.join("");
};

export const maskPhone = (phone = "") => {
  if (!phone) return "";
  const phoneArray = phone.split("");
  if (phone.substring(0, 2) === "55") {
    phoneArray.splice(0, 0, "+");
  }
  if (phoneArray[0] === "+") {
    phoneArray.splice(3, 0, " (");
    phoneArray.splice(6, 0, ") ");
    phoneArray.splice(12, 0, "-");
  } else {
    phoneArray.splice(0, 0, "(");
    phoneArray.splice(3, 0, ") ");
    phoneArray.splice(9, 0, "-");
  }
  return phoneArray.join("");
};
