import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 32px 0;
  height: calc(100vh - 64px);
  .anticon.anticon-loading.anticon-spin {
    width: 48px;
    height: 48px;
    svg {
      width: 48px;
      height: 48px;
      fill: var(--ant-primary-color);
    }
  }
`;

const Loading: React.FC = () => {
  return (
    <Container>
      <LoadingOutlined />
    </Container>
  );
};

export default Loading;
