import { Spin } from "antd";
import { BsEye } from "react-icons/bs";
import { formatCurrency } from "../../utils";
import { CurrencyFormItem, TextFormItem } from "../formItems";

const DividendosItem = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  valorRendimento: CurrencyFormItem({
    name: "valorRendimento",
    label: "Rendimento",
  }),
  impostoPagoExterior: CurrencyFormItem({
    name: "impostoPagoExterior",
    label: "Imposto Pago no Exterior",
  }),
};

export const DividendosRows = [
  [DividendosItem.id],
  [DividendosItem.valorRendimento],
  [DividendosItem.impostoPagoExterior],
];

const handleValue = (item: any, index: number, variable: string, data: any) => {
  const dividendo = data?.find((el: any) => el.month === 12 - index);
  if (dividendo) return dividendo[variable] * dividendo.dolarQuote;
  else return item[variable];
};

const handleValueBolsa = (item: any, data: any) => {
  const month = data.find((el: any) => el.month === item.month);
  return month?.value || 0;
};

const handleItem = (index: number, data: any, item: any) => {
  return data?.find((el: any) => el.month === 12 - index) ?? item;
};

export const DividendosTabs: (
  item: any,
  index: number,
  data: any,
  loading: boolean,
  setShowModal: Function,
  setItem: Function,
  setIndex: Function,
  isBolsa?: boolean
) => { class: string; tabs: { content: React.ReactNode }[] }[] = (
  item,
  index,
  data,
  loading,
  setShowModal,
  setItem,
  setIndex,
  isBolsa
) => [
  {
    class: "sub-header",
    tabs: [
      {
        content: <div className="monthName">{item.monthName}</div>,
      },
      {
        content: loading ? (
          <Spin style={{ display: "flex" }} />
        ) : (
          formatCurrency(
            isBolsa
              ? handleValueBolsa(item, data)
              : handleValue(item, index, "valorRendimento", data)
          )
        ),
      },
      ...(!isBolsa
        ? [
            {
              content: loading ? (
                <Spin style={{ display: "flex" }} />
              ) : (
                formatCurrency(
                  handleValue(item, index, "impostoPagoExterior", data)
                )
              ),
            },
          ]
        : []),
      {
        content: (
          <BsEye
            size={16}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setItem(handleItem(index, data, item));
              setIndex(index);
            }}
          />
        ),
      },
    ],
  },
];
