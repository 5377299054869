import moment from "moment";
import { checkDateExpiration, checkDateLimit, isValidDate } from "../../utils";
import {
  validationFieldRequired,
  validationNumberFieldPositive,
} from "../../utils/formValidations";
import {
  CurrencyFormItem,
  DateFormItem,
  NumberFormItem,
  TextFormItem,
} from "../../constants/formItems";

const TransferenciaCustodiaFormItems = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  codigo: (disabled: boolean) =>
    TextFormItem({
      name: "codigo",
      label: "Código",
      disabled,
      rules: validationFieldRequired,
    }),
  quantidade: (disabled: boolean) =>
    NumberFormItem({
      name: "quantidade",
      label: "Quantidade",
      disabled,
      rules: validationFieldRequired,
      decimalScale: 8,
    }),
  dataCompra: DateFormItem({
    name: "dataCompra",
    mask: "99/99/9999",
    label: "Data de compra",
    placeholder: "DD/MM/YYYY",
    rules: [
      {
        validator: (rule, value) =>
          !value || !isValidDate(value)
            ? Promise.reject(new Error("Informe uma data válida"))
            : value && !checkDateLimit("2017-01-01", value)
            ? Promise.reject(
                new Error("Informe uma data a partir de 01/01/2017")
              )
            : value &&
              !checkDateExpiration(moment().add(1, "day").toString(), value)
            ? Promise.reject(
                new Error(
                  `Informe uma data até ${moment().format("DD/MM/YYYY")}`
                )
              )
            : Promise.resolve(),
      },
    ],
  }),
  valorCompraUSDOrigemBRA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valorCompraUSDOrigemBRA",
    label: "Valor de compra (USD, Origem BRA)",
    placeholder: "US$ 0,00",
    rules: validationNumberFieldPositive,
  }),
  valorCompraUSDOrigemEUA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valorCompraUSDOrigemEUA",
    label: "Valor de compra (USD, Origem EUA)",
    placeholder: "US$ 0,00",
    rules: validationNumberFieldPositive,
  }),
};

export const TransferenciaCustodiaRows = (disabled: boolean) => [
  [TransferenciaCustodiaFormItems.id],
  [
    TransferenciaCustodiaFormItems.codigo(disabled),
    TransferenciaCustodiaFormItems.quantidade(disabled),
  ],
  [TransferenciaCustodiaFormItems.dataCompra],
  [TransferenciaCustodiaFormItems.valorCompraUSDOrigemBRA],
  [TransferenciaCustodiaFormItems.valorCompraUSDOrigemEUA],
];
