import { LoadingOutlined } from "@ant-design/icons";
import veloLogo from "../../assets/velotax/velologo.svg";
import { useBroker } from "../../contexts/BrokerContext";

export const LoadingSession: React.FC = () => {
  const { currentBroker } = useBroker();

  return (
    <div className="loading-session">
      <img
        src={currentBroker?.iconAlternative || currentBroker?.icon || veloLogo}
        style={currentBroker?.iconAlternativeStyle || {}}
        alt={currentBroker?.name || "Velotax Logo"}
      />
      <LoadingOutlined />
    </div>
  );
};
