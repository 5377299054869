import api from './apiBolsa';

export default async function HandleTag(activeCampaignTagId: string) {
    await api
    .post(
      "/ac",
      {
        activeCampaignTagId,
      },
    )
    .then()
    .catch((err) => {})
  };