import clsx from "clsx";
import moment from "moment";
import React, { useState } from "react";
import { Form, List, message, Typography } from "antd";
import { VscClose } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { InfoCircleOutlined } from "@ant-design/icons";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { FooterModal } from "../../components/FooterModal";
import {
  currencyToNumber,
  errorMessage,
  formatCurrency,
  months,
  numberToPercentage,
  replaceDotByComma,
  zero,
  isValidDate,
  isMobile,
  getQuoteFortnight,
} from "../../utils";
import {
  validationDateIsOnReferenceMonth,
  validationDateIsOnReferenceMonthCompra,
  validationFieldRequired,
  validationNumberAllowNegativeField,
  validationNumberFieldPositive,
} from "../../utils/formValidations";
import {
  CurrencyFormItem,
  DateFormItem,
  NumberFormItem,
  TextFormItem,
  SelectFormItem,
} from "../formItems";
import { useBroker } from "../../contexts/BrokerContext";

// import euaFlag from "../../assets/united-states-of-america.png";
// import brazilFlag from "../../assets/brazil.png";

export const yearConfigPL4173 = 2024

export const historicoVendasEmptyText =
  "Nenhuma operação de venda realizada neste mês";

export enum FormCurrencyMode {
  BRL = "R$",
  USD = "US$",
}

export enum FormMovimentTypeMode {
  VENDA = "Venda de ativos",
  JUROS = "Juros de aplicações",
}

export enum FormMovimentTypeModeNew {
  VENDA = "Venda de ativos",
  JUROS = "Juros de bonds",
  DIVIDENDOS = "Dividendos",
  OUTROS = "Outros rendimentos",
}

export const TipoMercado: { [key: string]: string } = {
  mercado: "Mercado à vista",
  mercadoAVista: "Mercado a Vista",
  mercadoOpcoes: "Mercado de opções",
  outros: "Outros mercados",
  mercadoFracionario: "Merc. Fracionário",
  opcaoCompra: "Opção de Compra",
  opcaoVenda: "Opção de Venda",
  dayTrade: "Day Trade",
};

export const TipoOperacao: { [key: string]: string } = {
  comum: "Comum",
  daytrade: "Day Trade",
  fii: "FII",
};

export enum YearResumeStatusFromBackEnum {
  PAYED = "PAYED",
  PENDING = "PENDING",
  REGULAR = "REGULAR",
  NOT_PAYED = "NOT_PAYED",
}

export enum YearResumeStatusCSSClassEnum {
  PAYED = "payed",
  PENDING = "pending",
  REGULAR = "not-payed",
  NOT_PAYED = "not-payed",
}

export enum YearResumeStatusColorEnum {
  PAYED = "var(--ant-success-color)",
  PENDING = "var(--ant-error-color)",
  REGULAR = "var(--ant-info-color-disabled)",
  NOT_PAYED = "var(--ant-info-color-disabled)",
}

export enum YearResumeStatusTextEnum {
  PAYED = "Imposto apurado",
  PENDING = "Imposto devido",
  REGULAR = "Não há imposto devido",
  NOT_PAYED = "DARF Emitido e não pago",
}

export interface IDarf {
  payed?: boolean;
  emitted?: boolean;
  multa: number;
  juros: number;
  totalAlienado: number;
  totalGanho: number;
  aliquotaDevida: number;
  impostoDevido: number;
  impostoAcumulado: number;
  totalImpostoDevido: number;
  memoriaCalculo: any[];
  regular?: boolean;
  status?: keyof typeof YearResumeStatusFromBackEnum;
  irrfExterior?: number; 
}

export interface IQuote {
  venda: number;
  compra: number;
}

export interface IQuotes {
  [key: string]: IQuote;
}

export const defaultDarf: IDarf = {
  totalAlienado: 0,
  impostoDevido: 0,
  impostoAcumulado: 0,
  totalImpostoDevido: 0,
  aliquotaDevida: 0,
  totalGanho: 0,
  juros: 0,
  multa: 0,
  memoriaCalculo: [],
  status: "REGULAR",
};

export interface IDarfBolsa {
  payed?: boolean;
  emitted?: boolean;
  aliquotaComum?: number;
  aliquotaDT?: number;
  aliquotaFII?: number;
  alienacoesComum?: number;
  alienacoesDT?: number;
  alienacoesFII?: number;
  ganhoComum?: number;
  ganhoDT?: number;
  ganhoFII?: number;
  impostoComumFinal?: number;
  impostoDTFinal?: number;
  impostoFIIFinal?: number;
  impostoTotal?: number;
  impostoTotalFinal?: number;
  irrfComum?: number;
  irrfDayTrade?: number;
  irrfFII?: number;
  irrfTotal?: number;
  irrfExterior?: number;
  jurosAux?: number;
  mercadoOpcoesComum?: number;
  mercadoOpcoesDT?: number;
  mercadoOutrosComum?: number;
  mercadoOutrosDT?: number;
  mercadoOutrosFII?: number;
  mercadoVistaComum?: number;
  mercadoVistaDT?: number;
  multaAux?: number;
  memoriaCalculo: any[];
  impostoAcumulado?: number;
  impostoDevido?: number;
  multa?: number;
  juros?: number;
  prejuizoComum?: number;
  prejuizoDayTrade?: number;
  prejuizoFII?: number;
  userPrejuizoComum?: number;
  userPrejuizoDayTrade?: number;
  userPrejuizoFII?: number;
  userIrrfComum?: number;
  userIrrfDayTrade?: number;
  userIrrfFII?: number;
  userIrrfTotal?: number;
  varTotaisComum?: number;
  varTotaisDT?: number;
  varTotaisFII?: number;
  totalImpostoDevido?: number;
  totalGanho?: number;
}

export const defaultDarfBolsa: IDarfBolsa = {
  alienacoesComum: 0,
  alienacoesDT: 0,
  alienacoesFII: 0,
  impostoComumFinal: 0,
  impostoDTFinal: 0,
  impostoFIIFinal: 0,
  impostoTotal: 0,
  impostoTotalFinal: 0,
  irrfComum: 0,
  irrfDayTrade: 0,
  irrfFII: 0,
  irrfTotal: 0,
  jurosAux: 0,
  mercadoOpcoesDT: 0,
  mercadoOutrosComum: 0,
  mercadoOutrosDT: 0,
  mercadoVistaComum: 0,
  mercadoVistaDT: 0,
  multaAux: 0,
  memoriaCalculo: [],
  impostoAcumulado: 0,
  prejuizoComum: 0,
  prejuizoDayTrade: 0,
  prejuizoFII: 0,
};

export const minDarfPrice = 10;
export const maxDarfPrice = 10000;
export const maxPixValue = 10000;
export const maxPixPayment = 10000;
export const maxCreditCardPayment = 10000;

const MultaModalProps = {
  title: "Multa de Atraso",
  content: (
    <div>
      <p>
        O pagamento dos impostos deve ser feito sempre até o último dia útil do
        mês subsequente aos ganhos realizados.
      </p>
      <p>
        Caso você tenha atrasado no pagamento do seu DARF, a sua multa de atraso
        será de 0,33% por dia de atraso (chegando, no máximo, a 20%) sobre o
        valor do principal, calculados a partir do primeiro dia útil depois do
        dia do vencimento do DARF.
      </p>
    </div>
  ),
};

const JurosModalProps = {
  title: "Juros de Atraso",
  content: (
    <div>
      <p>
        O pagamento dos impostos deve ser feito sempre até o último dia útil do
        mês subsequente aos ganhos realizados.
      </p>
      <p>
        Caso você tenha atrasado no pagamento do seu DARF, os seus juros de
        atraso serão equivalentes a taxa Selic acumulada até o mês anterior ao
        do pagamento, mais 1% no mês do pagamento, sobre o valor principal,
        contados a partir do mês subsequente ao vencimento do DARF.
      </p>
    </div>
  ),
};

export const MinPriceModalProps = {
  title: <>DARF abaixo de {formatCurrency(minDarfPrice)}</>,
  content: (
    <div>
      <p>
        O sistema da Receita Federal não permite a emissão de um único DARF com
        valor abaixo de {formatCurrency(minDarfPrice)}.
      </p>
      <p>
        Caso seu DARF tenha ficado com um valor abaixo de{" "}
        {formatCurrency(minDarfPrice)}, o valor será repassado automaticamente
        para o mês seguinte e ficará visível no campo{" "}
        <strong>
          <i>DARFs passados abaixo de {formatCurrency(minDarfPrice)}</i>
        </strong>
        .
      </p>
    </div>
  ),
};

const AliquotaModalProps = {
  title: "Alíquota sobre ganhos com ativos no exterior",
  content: (
    <div>
      <p>
        Caso o valor total vendido no mês seja superior a R$ 35 mil, os ganhos
        obtidos serão tributados a título de ganho de capital. As alíquotas
        (percentuais) aplicadas sobre o lucro são progressivas:
      </p>
      <List>
        <List.Item>
          a) 15% sobre a parcela dos ganhos que não ultrapassar R$ 5.000.000,00;
        </List.Item>
        <List.Item>
          b) 17,5% sobre a parcela dos ganhos que exceder R$ 5.000.000,00 e não
          ultrapassar R$ 10.000.000,00;
        </List.Item>
        <List.Item>
          c) 20% sobre a parcela dos ganhos que exceder R$ 10.000.000,00 e não
          ultrapassar R$ 30.000.000,00; e
        </List.Item>
        <List.Item>
          d) 22,5% sobre a parcela dos ganhos que ultrapassar R$ 30.000.000,00.
        </List.Item>
      </List>
    </div>
  ),
};

const EmitirDarfModalProps = (onCancel: () => void, onOk: () => void) => ({
  title: "Você confirma a emissão do DARF?",
  footer: <FooterModal dark onCancel={onCancel} onOk={onOk} />,
  content: (
    <div>
      <p>
        O arquivo gerado ficará disponível na aba Histórico e será enviado para
        o seu e-mail cadastrado.
      </p>
    </div>
  ),
});

const ReemitirDarfModalProps = (onCancel: () => void, onOk: () => void) => ({
  title: "Você confirma a emissão de um novo DARF?",
  footer: <FooterModal dark onCancel={onCancel} onOk={onOk} />,
  content: (
    <div>
      <p>
        <b>Ao confirmar, o DARF anterior será substituido.</b> O arquivo gerado
        ficará disponível na aba Histórico e será enviado para o seu e-mail
        cadastrado.
      </p>
    </div>
  ),
});

export const DarfModal = (
  reemit: boolean,
  onCancel: () => void,
  onOk: () => void
) =>
  reemit
    ? ReemitirDarfModalProps(onCancel, onOk)
    : EmitirDarfModalProps(onCancel, onOk);

interface IEdit {
  value: any;
  id: string;
  isEditting: boolean;
}
interface DarfResultDescriptionsProps {
  id: string;
  label: (onClick: Function) => React.ReactNode;
  Component: React.FC<{
    data: IDarf | IDarfBolsa;
    url: string;
    year: number;
    month: number;
    loading: boolean;
    disabled: boolean;
    view?: boolean;
    handleEdit?: Function;
    setDataCripto?: React.Dispatch<React.SetStateAction<IDarf>>;
    setDataBolsa?: React.Dispatch<React.SetStateAction<IDarfBolsa>>;
  }>;
}

export const DarfResultDescriptionsExterior: DarfResultDescriptionsProps[] = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data, view, year, month }) =>
      formatCurrency(
        Math.max((Number(data["impostoDevido"] || 0) + ((year < yearConfigPL4173 || month == 0) ? Number(data["impostoAcumulado"] || 0) : 0
      )) - Number(data["irrfExterior"] || 0), 0)
      ),
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data, view }) =>
      formatCurrency(Number(data["impostoDevido"] || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ {minDarfPrice}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      url,
    }) => {
      const { currentPage } = useBroker();
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          (currentPage?.api || apis)
            .post(`${url}/updateDataDarf`, {
              year,
              month: month + 1,
              [edit.id]: edit.value,
            })
            .then(() =>
              (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                ...data,
                [edit.id]: edit.value,
              }))
            )
            .catch(() => message.error(errorMessage))
            .finally(() => handleEditValueCancel());
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content is-editting">
          {!isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            Ok
          </Button>
          {isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled && year < yearConfigPL4173,
          })}
        >
          {formatCurrency(Number(data.impostoAcumulado || 0))}
          {(disabled && year < yearConfigPL4173) && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "irrfTotal",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
      </div>
    ),
    Component: ({ data, handleEdit }) => (
      <div className="desc-content ml-40">
        {formatCurrency(
          Number(
            (data as IDarfBolsa)["userIrrfTotal"] ||
              (data as IDarfBolsa)["irrfTotal"] ||
              0
          )
        )}
        <Button
          type="text"
          icon={<FaRegEdit />}
          onClick={() => handleEdit?.()}
        />
      </div>
    ),
  },
  {
    id: "irrfExterior",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
      </div>
    ),
    Component: ({ data, handleEdit }) => {
      return (
      <div className="desc-content">
        {formatCurrency(
          Number(
              (data as IDarfBolsa)["irrfExterior"] ||
              0
          )
        )}
        {/* <Button
          type="text"
          icon={<FaRegEdit />}
          onClick={() => handleEdit?.()}
        /> */}
      </div>
    )},
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) - (data["irrfExterior"] || 0) >=
          minDarfPrice
          ? Number(data["multa"])
          : 0
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) - (data["irrfExterior"] || 0) >=
          minDarfPrice
          ? Number(data["juros"])
          : 0
      ),
  },
];

export const DarfResultDescriptions: DarfResultDescriptionsProps[] = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data, view }) =>
      formatCurrency(
        Number(data["impostoDevido"] || 0) +
          Number(data["impostoAcumulado"] || 0)
      ),
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data, view }) =>
      formatCurrency(Number(data["impostoDevido"] || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ {minDarfPrice}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      url,
    }) => {
      const { currentPage } = useBroker();
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          (currentPage?.api || apis)
            .post(`${url}/updateDataDarf`, {
              year,
              month: month + 1,
              [edit.id]: edit.value,
            })
            .then(() =>
              (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                ...data,
                [edit.id]: edit.value,
              }))
            )
            .catch(() => message.error(errorMessage))
            .finally(() => handleEditValueCancel());
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content is-editting">
          {!isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            Ok
          </Button>
          {isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "irrfTotal",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
      </div>
    ),
    Component: ({ data, handleEdit }) => (
      <div className="desc-content ml-40">
        {formatCurrency(
          Number(
            (data as IDarfBolsa)["userIrrfTotal"] ||
              (data as IDarfBolsa)["irrfTotal"] ||
              0
          )
        )}
        <Button
          type="text"
          icon={<FaRegEdit />}
          onClick={() => handleEdit?.()}
        />
      </div>
    ),
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) >=
          minDarfPrice
          ? Number(data["multa"])
          : 0
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) >=
          minDarfPrice
          ? Number(data["juros"])
          : 0
      ),
  },
];

export const DarfImpostosDevidosDescriptions = [
  {
    id: "totalGanho",
    label: () => "Ganho total",
  },
  {
    id: "prejuizoExterior",
    label: () => "(-) Prejuízos a compensar",
    render: (data: any, view?: boolean, year?: number) => {
      return formatCurrency(Number(data["prejuizoExterior"] || 0))
    },
  },
  {
    id: "aliquotaDevida",
    label: (onClick: any, year?: number) => (
      <div className="desc-label">
        (x) Alíquota devida
        {(year || 0) < yearConfigPL4173 && <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(AliquotaModalProps)}
        />}
      </div>
    ),
    render: (data: any, view?: any, year?: number) =>
      `${replaceDotByComma(
        Number(((year || 0) >= yearConfigPL4173 ? 15 : (data["aliquotaDevida"] || 0)).toFixed(2))
      )} %`,
  },
  {
    id: "impostoDevido",
    label: () => "Imposto devido",
    render: (data: any, view?: boolean, year?: number) =>
      formatCurrency(
        !view
          ? Number(data["impostoDevido"] || data["totalImpostoDevido"] || 0)
          : Number(data["impostoDevido"] || data["totalImpostoDevido"] || 0)
        // -
        //     Number(data["impostoDevido"] ? data["impostoAcumulado"] || 0 : 0)
      ),
  },
];

const FormItemsBolsa = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  date: (month: number, year: number) =>
    DateFormItem({
      name: "date",
      label: `Dia do mês de ${months[month]}/${year}`,
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  code: (placeholder: string) =>
    TextFormItem({
      name: "code",
      label: "Ativo",
      placeholder,
      rules: validationFieldRequired,
    }),
  quantity: (placeholder: string) =>
    NumberFormItem({
      name: "quantity",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  avgBuyPrice: CurrencyFormItem({
    name: "avgBuyPrice",
    label: "Custo de compra",
  }),
  price: CurrencyFormItem({ name: "price", label: "Preço de venda" }),
  feeB3: CurrencyFormItem({ name: "feeB3", label: "Taxas" }),
  capitalGain: CurrencyFormItem({
    name: "capitalGain",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.price
        ? currencyToNumber(changed.price)
        : values.price;
      const custoCompra = changed.avgBuyPrice
        ? currencyToNumber(changed.avgBuyPrice)
        : values.avgBuyPrice;
      const taxas = changed.feeB3
        ? currencyToNumber(changed.feeB3)
        : values.feeB3;
      return Number(values.quantity) * (precoVenda - custoCompra) - taxas;
    },
  }),
  operacao: SelectFormItem({
    name: "operacao",
    label: "Operação",
    placeholder: "Selecione",
    options: [
      { label: "Comum", value: "Comum" },
      { label: "Day Trade", value: "Day Trade" },
      { label: "Fundos Imobiliários", value: "FII" },
    ],
  }),
  market: ({ data }: any) =>
    SelectFormItem({
      name: "market",
      label: "Mercado",
      placeholder: "Selecione",
      options: [
        { label: "Mercado à vista", value: "Mercado a Vista" },
        ...(data.operacao !== "FII"
          ? [
              { label: "Mercado de opções", value: "Mercado de opções" },
              { label: "Outros mercados", value: "Outros mercados" },
            ]
          : []),
      ],
      onChange: (changed, values) =>
        values.operacao === "FII" && values.market !== "Mercado a Vista"
          ? "Mercado a Vista"
          : values.market,
    }),
};

// const exchange = ({
//   changed,
//   values,
//   modeProp,
//   prop,
//   cotacaoProp,
// }: {
//   changed: any;
//   values: any;
//   modeProp: string;
//   prop: string;
//   cotacaoProp: string;
// }) => {
//   return changed.hasOwnProperty(modeProp)
//     ? values[cotacaoProp] && changed[modeProp]
//       ? values[prop] * values[cotacaoProp]
//       : values[prop] / values[cotacaoProp]
//     : changed[prop]
//     ? currencyToNumber(
//         changed[prop],
//         values[modeProp] !== false ? FormCurrencyMode.BRL : FormCurrencyMode.USD
//       )
//     : values[prop];
// };

const FormItemsCrypto = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  data: (month: number, year: number) =>
    DateFormItem({
      name: "data",
      label: `Dia do mês de ${months[month]}/${year}`,
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  ativo: (placeholder: string) =>
    TextFormItem({
      name: "ativo",
      label: "Ativo",
      placeholder,
      rules: validationFieldRequired,
    }),
  quantidade: (placeholder: string) =>
    NumberFormItem({
      name: "quantidade",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  precoVenda: CurrencyFormItem({ name: "precoVenda", label: "Preço de venda" }),
  custoCompra: CurrencyFormItem({
    name: "custoCompra",
    label: "Custo de compra",
  }),
  taxas: CurrencyFormItem({ name: "taxas", label: "Taxas" }),
  ganho: CurrencyFormItem({
    name: "ganho",
    label: "Ganho",
    disabled: true,
    onChange: (changed, values) => {
      const precoVenda = changed.precoVenda
        ? currencyToNumber(changed.precoVenda)
        : values.precoVenda;
      const custoCompra = changed.custoCompra
        ? currencyToNumber(changed.custoCompra)
        : values.custoCompra;
      const taxas = changed.taxas
        ? currencyToNumber(changed.taxas)
        : values.taxas;
      return Number(
        Math.max(
          0,
          Number(values.quantidade) * (precoVenda - custoCompra) - taxas
        ).toFixed(2)
      );
    },
  }),
  operacao: SelectFormItem({
    name: "operacao",
    label: "Operação",
    placeholder: "Selecione",
    options: [
      { label: "Comum", value: "comum" },
      { label: "Day Trade", value: "daytrade" },
    ],
  }),
  mercado: SelectFormItem({
    name: "mercado",
    label: "Mercado",
    placeholder: "Selecione",
    options: [
      { label: "Mercado à vista", value: "mercado" },
      { label: "Mercado de opções", value: "mercadoOpcoes" },
      { label: "Outros mercados", value: "outros" },
    ],
  }),
};

const FormItemsExterior = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  movimentType: (movimentType: any, setMovimentType: any, year: number, fromApi?: boolean) =>
    SelectFormItem({
      name: "movimentType",
      label: "Tipo de movimentação",
      value: movimentType,
      disabled: fromApi,
      defaultValue: FormMovimentTypeMode.VENDA,
      rules: [],
      options: year < yearConfigPL4173 
      ? [
        { label: "Venda de ativos", value: FormMovimentTypeMode.VENDA },
        { label: "Juros de aplicações", value: FormMovimentTypeMode.JUROS },
      ] 
      : [
        { label: "Venda de ativos", value: FormMovimentTypeModeNew.VENDA },
        { label: "Juros de bonds", value: FormMovimentTypeModeNew.JUROS },
        { label: "Dividendos", value: FormMovimentTypeModeNew.DIVIDENDOS },
        { label: "Outros rendimentos", value: FormMovimentTypeModeNew.OUTROS },
      ],
      onChange(changed, values) {
        setMovimentType(
          changed.movimentType !== undefined
            ? changed.movimentType
            : movimentType
        );
        return changed.movimentType !== undefined
          ? changed.movimentType
          : movimentType;
      },
    }),
  ativo: (placeholder: string, fromApi?: boolean) =>
    TextFormItem({
      name: "ativo",
      label: "Ativo",
      disabled: fromApi,
      placeholder,
      rules: validationFieldRequired,
    }),
  valueOriginBRA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valueOriginBRA",
    label: "Origem BRA (US$)",
    placeholder: "US$ 0,00",
  }),
  valueOriginEUA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valueOriginEUA",
    label: "Origem USA (US$)",
    placeholder: "US$ 0,00",
  }),
  dataCompra: () =>
    DateFormItem({
      name: "dataCompra",
      label: "Data da Liquidação",
      placeholder: "DD/MM/YYYY",
      mask: `99/99/9999`,
      rules: validationDateIsOnReferenceMonthCompra,
    }),
  cotacaoCompra: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioCompra",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.dataCompra)) {
          let i = 0;
          let keycompra = changed.dataCompra || values.dataCompra;
          const isComplete = !String(keycompra).includes("_");
          if (!keycompra || !isComplete) return values.cotacaoCompra;
          while (isComplete && !quotations[keycompra] && i <= 10) {
            keycompra = moment(keycompra, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          const { venda } = quotations[keycompra] || { venda: 0 };
          return venda;
        }
      },
    }),
  precoVenda: CurrencyFormItem({
    prefix: "US$ ",
    name: "valor",
    placeholder: "US$ 0,00",
    label: "Valor Bruto (US$)",
  }),
  fee: CurrencyFormItem({
    name: "fee",
    label: "Taxa de corretagem (US$)",
    placeholder: "US$ 0,00",
    prefix: "US$ ",
  }),
  dataVenda: (month: number, year: number) =>
    DateFormItem({
      name: "data",
      label: "Data da Liquidação",
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  dataVendaFee: (month: number, year: number) =>
    DateFormItem({
      name: "dataFee",
      label: "Data da Liquidação",
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  cotacaoVenda: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioVenda",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.data)) {
          let i = 0;
          let keyvenda = changed.data || values.data;
          const isComplete = !String(keyvenda).includes("_");
          if (!keyvenda || !isComplete) return values.cotacaoVenda;
          while ((isComplete && !quotations[keyvenda]) || i === 5) {
            keyvenda = moment(keyvenda, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          const { compra } = quotations[keyvenda] || { compra: 0 };
          return compra;
        }
      },
    }),
  cotacaoVendaFee: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioVendaFee",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.dataFee)) {
          let i = 0;
          let keyvenda = changed.dataFee || values.dataFee;
          const isComplete = !String(keyvenda).includes("_");
          if (!keyvenda || !isComplete) return values.cotacaoVendaFee;
          while ((isComplete && !quotations[keyvenda]) || i === 5) {
            keyvenda = moment(keyvenda, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          if (values.movimentType == 'Dividendos') {
            const day = keyvenda.split('/')[0];
            const month = keyvenda.split('/')[1];
            const year = keyvenda.split('/')[2];
            const dateToQuote = new Date(`${month}/${day}/${year}`);
            const cotacaoCompra = getQuoteFortnight(dateToQuote, quotations);
            return cotacaoCompra;
          }

          const { venda } = quotations[keyvenda] || { venda: 0 };
          return venda;
        }
      },
    }),
  ganho: CurrencyFormItem({
    name: "ganho",
    label: `Ganho (R$)`,
    disabled: true,
    rules: [],
    onChange: (changed, values) => {
      // TODO Atualmente há perda de centavos entre a conversão sucessiva entre BRL e USD
      let A = changed.valueOriginBRA
        ? currencyToNumber(changed.valueOriginBRA, "US$")
        : values.valueOriginBRA || 0;
      let B = changed.valueOriginEUA
        ? currencyToNumber(changed.valueOriginEUA, "US$")
        : values.valueOriginEUA || 0;
      const C = values.cambioCompra || 1;
      const D = changed.valor
        ? currencyToNumber(changed.valor, "US$")
        : values.valor || 0;
      const E = changed.fee
        ? currencyToNumber(changed.fee, "US$")
        : values.fee || 0;
      const F = values.cambioVenda || 1;

      const correctionZero = 1e-50;
      if (A === 0) A = correctionZero;
      if (B === 0) B = correctionZero;

      const ganho =
        (A / (A + B)) * (D - E) * F - A * C + ((B / (A + B)) * (D - E) - B) * F;

      return Number(Math.max(ganho, 0).toFixed(2));
    },
  }),
  ganhoFee: CurrencyFormItem({
    name: "ganhoFee",
    label: `Rendimento (R$)`,
    disabled: true,
    rules: [],
    onChange: (changed, values) => {
      // TODO Atualmente há perda de centavos entre a conversão sucessiva entre BRL e USD
      const A = values.cambioVendaFee || 1;

      const B = changed.valor
        ? currencyToNumber(changed.valor, "US$")
        : values.valor || 0;

      const C = changed.fee
        ? currencyToNumber(changed.fee, "US$")
        : values.fee || 0;

      const ganho = (B - C) * A;
      return Number(Math.max(ganho, 0).toFixed(2));
    },
  }),
  date: (month: number, year: number, fromApi?: boolean) =>
    DateFormItem({
      name: "data",
      disabled: fromApi,
      label: `Dia do mês de ${months[month]}/${year}`,
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  quantidade: (placeholder: string, fromApi?: boolean) =>
    NumberFormItem({
      disabled: fromApi,
      name: "quantidade",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  valor: CurrencyFormItem({ name: "sellPrice_BRL", label: "Preço de venda (R$)" }),
  valorNewModel: (fromApi?: boolean) => 
    CurrencyFormItem({ name: "sellPrice_BRL", disabled: fromApi, label: "Preço de venda (R$)" }),
  capitalGain: CurrencyFormItem({
    name: "ganho",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.valor
        ? currencyToNumber(changed.valor)
        : values.valor;
      const custoCompra = changed.valueOriginBRA
        ? currencyToNumber(changed.valueOriginBRA)
        : values.valueOriginBRA;
      const taxas = changed.fee
        ? currencyToNumber(changed.fee)
        : values.fee;
      return Number(values.quantidade) * (precoVenda - custoCompra) - taxas;
    },
  }),
  capitalGainBRL: CurrencyFormItem({
    name: "varTotalBRL",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.sellPrice_BRL
        ? currencyToNumber(changed.sellPrice_BRL)
        : values.sellPrice_BRL;
      const custoCompra = changed.buyPrice_BRL
        ? currencyToNumber(changed.buyPrice_BRL)
        : values.buyPrice_BRL;
      const taxas = changed.fee_BRL
        ? currencyToNumber(changed.fee_BRL)
        : values.fee_BRL;
      return Number(values.quantidade) * (precoVenda - custoCompra) - taxas;
    },
  }),
  valueOriginBRL: (fromApi?: boolean) => CurrencyFormItem({
    disabled: fromApi,
    prefix: "R$ ",
    name: "buyPrice_BRL",
    label: "Custo de Compra (R$)",
    placeholder: "R$ 0,00",
  }),
  feeBRL: (fromApi?: boolean) => CurrencyFormItem({
    disabled: fromApi,
    name: "fee_BRL",
    label: "Taxa de corretagem (R$)",
    placeholder: "R$ 0,00",
    prefix: "R$ ",
  }),
};

const PrejuFormItems = {
  userPrejuizoComum: CurrencyFormItem({
    name: "userPrejuizoComum",
    label: "Prejuízo Comum",
  }),
  userPrejuizoDayTrade: CurrencyFormItem({
    name: "userPrejuizoDayTrade",
    label: "Prejuízo Day Trade",
  }),
  userPrejuizoFII: CurrencyFormItem({
    name: "userPrejuizoFII",
    label: "Prejuízo FII",
  }),
};

const IrrfFormItem = {
  userIrrfComum: CurrencyFormItem({
    name: "userIrrfComum",
    label: "IRRF Comum",
  }),
  userIrrfDayTrade: CurrencyFormItem({
    name: "userIrrfDayTrade",
    label: "IRRF Day Trade",
  }),
  userIrrfFII: CurrencyFormItem({
    name: "userIrrfFII",
    label: "IRRF FII",
  }),
  userIrrfTotal: CurrencyFormItem({
    name: "userIrrfTotal",
    label: "IRRF Total (Comum + Day Trade + FII)",
    disabled: true,
    onChange: (changed, values) => {
      const userIrrfComum = changed.userIrrfComum
        ? currencyToNumber(changed.userIrrfComum)
        : values.userIrrfComum;
      const userIrrfDayTrade = changed.userIrrfDayTrade
        ? currencyToNumber(changed.userIrrfDayTrade)
        : values.userIrrfDayTrade;
      const userIrrfFII = changed.userIrrfFII
        ? currencyToNumber(changed.userIrrfFII)
        : values.userIrrfFII;
      return (
        Number(userIrrfComum ?? 0) +
        Number(userIrrfDayTrade ?? 0) +
        Number(userIrrfFII ?? 0)
      );
    },
  }),
};

export const IrrfFormItemRows = [
  [IrrfFormItem.userIrrfComum],
  [IrrfFormItem.userIrrfDayTrade],
  [IrrfFormItem.userIrrfFII],
  [IrrfFormItem.userIrrfTotal],
];

export const PrejuFormItemRows = [
  [PrejuFormItems.userPrejuizoComum],
  [PrejuFormItems.userPrejuizoDayTrade],
  [PrejuFormItems.userPrejuizoFII],
];

export const DarfFormItemRows = (month: number, year: number) => [
  [FormItemsCrypto.id],
  [FormItemsCrypto.data(month, year), FormItemsCrypto.ativo("Ex: BTC")],
  [FormItemsCrypto.quantidade("Ex: 0,00101")],
  [FormItemsCrypto.precoVenda, FormItemsCrypto.custoCompra],
  [FormItemsCrypto.taxas],
  [FormItemsCrypto.ganho],
];

export const ExteriorFormItemRows = (
  month: number,
  year: number,
  quotations: IQuotes,
  editAsset: any,
  asset: any,
  movimentType: string,
  setMovimentType: React.Dispatch<React.SetStateAction<string>>
) => {
  return [
    asset && !asset?.valueOriginBRA
      ? []
      : asset
      ? [FormItemsExterior.ativo("Ex: XP")]
      : [
          FormItemsExterior.movimentType(movimentType, setMovimentType, year),
          FormItemsExterior.ativo("Ex: XP"),
        ],
    asset && !asset?.valueOriginBRA
      ? []
      : [
          {
            label: "",
            name: "",
            input: (
              <div className="exterior-content-title">
                {movimentType === "Juros de aplicações" ? "Juros" : "Compra"}
              </div>
            ),
          },
        ],
    asset && !asset?.valueOriginBRA
      ? []
      : movimentType !== "Juros de aplicações"
      ? [FormItemsExterior.valueOriginBRA, FormItemsExterior.valueOriginEUA]
      : [],
    asset && !asset?.valueOriginBRA
      ? []
      : movimentType !== "Juros de aplicações"
      ? [
          FormItemsExterior.dataCompra(),
          FormItemsExterior.cotacaoCompra(quotations),
        ]
      : [],
    asset && !asset?.valueOriginBRA
      ? []
      : movimentType !== "Juros de aplicações"
      ? [
          {
            label: "",
            name: "",
            input: <div className="exterior-content-title">Venda</div>,
          },
        ]
      : [],
    asset && !asset?.valueOriginBRA
      ? []
      : [FormItemsExterior.precoVenda, FormItemsExterior.fee],
    asset && !asset?.valueOriginBRA
      ? []
      : movimentType !== "Juros de aplicações"
      ? [
          FormItemsExterior.dataVenda(month, year),
          FormItemsExterior.cotacaoVenda(quotations),
        ]
      : [
          FormItemsExterior.dataVendaFee(month, year),
          FormItemsExterior.cotacaoVendaFee(quotations),
        ],
    asset && !asset?.valueOriginBRA
      ? []
      : movimentType !== "Juros de aplicações"
      ? [FormItemsExterior.ganho]
      : [FormItemsExterior.ganhoFee],
  ];
};

export const ExteriorNewFormItemRows = (
  month: number,
  year: number,
  quotations: IQuotes,
  editAsset: any,
  asset: any,
  movimentType: string,
  setMovimentType: React.Dispatch<React.SetStateAction<string>>,
  fromApi?: boolean
) => {
  return [
    asset ? [FormItemsExterior.ativo("Ex: XP", fromApi)]
      : movimentType === "Venda de ativos" ? [FormItemsExterior.movimentType(movimentType, setMovimentType, year, fromApi)] 
      : [
        FormItemsExterior.movimentType(movimentType, setMovimentType, year, fromApi),
        FormItemsExterior.ativo("Ex: XP", fromApi),
      ],
    movimentType === "Venda de ativos" ? [] : [
          {
            label: "",
            name: "",
            input: (
              <div className="exterior-content-title">
                {
                  ['Juros de bonds'].includes(movimentType || '') ? "Juros" 
                  : ['Dividendos'].includes(movimentType || '') ? "Dividendos" 
                  : "Rendimentos"}
              </div>
            ),
          },
        ],
    movimentType === "Venda de ativos"
      ? [FormItemsExterior.date(month, year, fromApi), FormItemsExterior.ativo("Ex: XP", fromApi)]
      : [],
    movimentType === "Venda de ativos"
      ? [
          FormItemsExterior.quantidade("Ex: 100", fromApi),
          FormItemsExterior.valorNewModel(fromApi),
        ]
      : [],
    movimentType === 'Venda de ativos' ? [FormItemsExterior.valueOriginBRL(fromApi), FormItemsExterior.feeBRL(fromApi)]
      : [FormItemsExterior.precoVenda, FormItemsExterior.fee],
    movimentType === "Venda de ativos"
      ? [
          FormItemsExterior.capitalGainBRL,
        ]
      : [
          FormItemsExterior.dataVendaFee(month, year),
          FormItemsExterior.cotacaoVendaFee(quotations),
        ],
    movimentType === "Venda de ativos"
      ? []
      : [FormItemsExterior.ganhoFee],
  ];
};

export const DarfBolsaFormItemRows = (month: number, year: number) => [
  [FormItemsBolsa.id],
  [FormItemsBolsa.operacao, FormItemsBolsa.market],
  [FormItemsBolsa.date(month, year), FormItemsBolsa.code("Ex: PETR3")],
  [FormItemsBolsa.quantity("Ex: 100"), FormItemsBolsa.price],
  [FormItemsBolsa.avgBuyPrice, FormItemsBolsa.feeB3],
  [FormItemsBolsa.capitalGain],
];

export const DarfBolsaTabs: (
  data: IDarfBolsa,
  rest: any
) => { class: string; tabs: { content: React.ReactNode }[] }[] = (
  data,
  rest
) => [
  {
    class: "header",
    tabs: [
      {
        content: "",
      },
      {
        content: "Comum",
      },
      {
        content: "Day Trade",
      },
      {
        content: "FII",
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Ganhos e perdas totais",
      },
      {
        content: formatCurrency(data.varTotaisComum ?? 0),
      },
      {
        content: formatCurrency(data.varTotaisDT ?? 0),
      },
      {
        content: formatCurrency(data.varTotaisFII ?? 0),
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Ganhos tributáveis",
      },
      {
        content: formatCurrency(data.ganhoComum ?? 0),
      },
      {
        content: formatCurrency(data.ganhoDT ?? 0),
      },
      {
        content: formatCurrency(data.ganhoFII ?? 0),
      },
    ],
  },
  {
    class: "sub-header opacity",
    tabs: [
      {
        content: (
          <>
            (-) Prejuízos a compensar
            {!rest.view && (
              <Button
                type="text"
                icon={<FaRegEdit />}
                onClick={rest.handleEditPreju}
              />
            )}
          </>
        ),
      },
      {
        content: formatCurrency(
          data.userPrejuizoComum ?? data.prejuizoComum ?? 0
        ),
      },
      {
        content: formatCurrency(
          data.userPrejuizoDayTrade ?? data.prejuizoDayTrade ?? 0
        ),
      },
      {
        content: formatCurrency(data.userPrejuizoFII ?? data.prejuizoFII ?? 0),
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Base de cálculo",
      },
      {
        content: formatCurrency(
          Math.max(
            (data.ganhoComum ?? 0) -
              (data.userPrejuizoComum
                ? data.userPrejuizoComum ?? 0
                : data.prejuizoComum ?? 0),
            0
          )
        ),
      },
      {
        content: formatCurrency(
          Math.max(
            (data.ganhoDT ?? 0) -
              (data.userPrejuizoDayTrade
                ? data.userPrejuizoDayTrade ?? 0
                : data.prejuizoDayTrade ?? 0),
            0
          )
        ),
      },
      {
        content: formatCurrency(
          Math.max(
            (data.ganhoFII ?? 0) -
              (data.userPrejuizoFII
                ? data.userPrejuizoFII ?? 0
                : data.prejuizoFII ?? 0),
            0
          )
        ),
      },
    ],
  },
  {
    class: "sub-header opacity",
    tabs: [
      {
        content: "(x) Alíquota devida",
      },
      {
        content: numberToPercentage(15, 0),
      },
      {
        content: numberToPercentage(20, 0),
      },
      {
        content: numberToPercentage(20, 0),
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Imposto devido",
      },
      {
        content: formatCurrency(data.totalImpostoDevido ?? 0),
      },
      {
        content: formatCurrency(data.impostoDTFinal ?? 0),
      },
      {
        content: formatCurrency(data.impostoFIIFinal ?? 0),
      },
    ],
  },
];

export const FreeLimitText = (
  <Typography
    style={{
      fontSize: "14px",
      display: "flex",
      columnGap: "8px",
      lineHeight: "18px",
      alignItems: "flex-start",
    }}
  >
    <InfoCircleOutlined style={{ fontSize: "16px", marginTop: "2px" }} />{" "}
    <span>
      Você atingiu o limite de DARFS emitidos/salvos no plano gratuito.{" "}
      <strong>Atualize o seu plano</strong>
    </span>
  </Typography>
);

export const SuportPremiumText = (
  <Typography
    style={{
      fontSize: "14px",
      display: "flex",
      columnGap: "8px",
      lineHeight: "18px",
      alignItems: "flex-start",
    }}
  >
    <InfoCircleOutlined style={{ fontSize: "16px", marginTop: "2px" }} />{" "}
    <span>
      O suporte ao vivo está disponível apenas para usuários do Plano PREMIUM.{" "}
      <strong>Atualize o seu plano</strong>
    </span>
  </Typography>
);
