import React, { Dispatch, SetStateAction } from "react";
import { Plan } from "../../../../../constants/plans";
import { ErrorScreen } from "./components/ErrorScreen";
import { ConfirmedScreen } from "./components/ConfirmScreen";

interface IResultScreen {
  plan?: Plan;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  status?: string;
}

export const ResultScreen: React.FC<IResultScreen> = ({
  plan,
  setCurrentStep,
  status = "pending",
}) => {
  return (
    <>
      {status === "active" ? (
        <ConfirmedScreen plan={plan} />
      ) : (
        <ErrorScreen setCurrentStep={setCurrentStep} />
      )}
    </>
  );
};
