import { HTMLAttributes } from "react";

interface CopyTextProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
}

export const CopyText: React.FC<CopyTextProps> = ({ children, ...props }) => {
  const selectText = () => {
    if ((document as any).selection) {
      // Internet Explorer
      const range = (document.body as any).createTextRange?.();
      range?.moveToElementText?.(document.getElementById(props.id)!);
      range?.select();
    } else if (window.getSelection) {
      const range = document.createRange?.();
      range?.selectNode?.(document.getElementById(props.id)!);
      window.getSelection?.()?.removeAllRanges?.();
      window.getSelection?.()?.addRange?.(range);
    }
  };

  return (
    <div
      {...props}
      onClick={(e) => {
        selectText();
        props.onClick?.(e);
      }}
    >
      {children}
    </div>
  );
};
